/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';

export const updateAmountActionComponentProps = {
  type: 'button',
  showIcon: true,
  icon: ICONS.protocol_value.info.amount_used,
  text: 'Update Amount Remaining',
  elementProps: {
    basic: true,
    fluid: true,
  },
} as ActionComponentProps;
