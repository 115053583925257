/**
 * Labstep
 *
 * @module prosemirror/nodes/code
 * @desc This is Labstep's custom code block that uses CodeMirror
 * TODO Merge code and code_block blocks
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { Node as ProseMirrorNode } from 'prosemirror-model';

const getAttrs = (node: HTMLElement | string) => {
  if (node instanceof HTMLElement) {
    const mode = node.getAttribute('data-code-mode');
    const value = node.getAttribute('data-code-value');
    if (!mode) {
      return false;
    }
    return {
      mode,
      value,
    };
  }
  return false;
};

const NodeStep: LabstepNode = {
  key: 'codemirror',
  spec: {
    attrs: {
      mode: {},
      value: {},
    },
    group: 'block',
    marks: '',
    parseDOM: [{ tag: 'div', getAttrs }],
    toDOM(node: ProseMirrorNode) {
      const { mode, value } = node.attrs;
      return [
        'div',
        { 'data-code-mode': mode, 'data-code-value': value },
      ];
    },
  },
};

export default NodeStep;
