/**
 * Labstep
 *
 * @module epics/websocket-room
 * @desc Redux epic for websocket room
 */

import { Action } from 'labstep-web/models/action.model';
import { websocketService } from 'labstep-web/services/websocket.service';
import { selectWebsocketRoom } from 'labstep-web/state/selectors/websocket';
import { LabstepReduxState } from 'labstep-web/state/types';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  mergeMap,
  tap,
} from 'rxjs/operators';

/**
 * When location changes, join a new room.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onLocationChangeUpdateRoomActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'LOCATION_CHANGE' &&
        action.meta &&
        action.meta.path,
    ),
    mergeMap((action: Action) => {
      if (!action.meta.path) {
        return of({
          type: 'JOIN_ROOM_NO_PATH',
        });
      }

      const { path } = action.meta;

      const parts = path.split('/');

      let room = null;

      if (parts.length > 2) {
        if (path.startsWith('/experiment-workflow/')) {
          room = `experiment-workflow:${parts[2]}`;
        } else if (path.includes('/reporting/')) {
          room = `group:${parts[2]}:reporting`;
        }
      }

      if (room === null) {
        return of({
          type: 'JOIN_ROOM',
          meta: {
            room: null,
          },
        });
      }

      const currentRoom = selectWebsocketRoom(state$.value);

      if (room !== currentRoom) {
        return of({
          type: 'JOIN_ROOM',
          meta: {
            room,
          },
        });
      }

      return of({
        type: 'JOIN_ROOM_ALREADY_JOINED',
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_LOCATION_CHANGE_UPDATE_ROOM',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * When joining a room, send a joinRoom message to the websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onJoinRoomActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'JOIN_ROOM'),
    tap((action: Action) => {
      websocketService.joinRoom(action.meta.room);
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_LOCATION_CHANGE_UPDATE_ROOM',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * When websocket connects, join room if any.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onWebsocketConnectJoinRoomActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'WEBSOCKET_CONNECT'),
    mergeMap(() => {
      const room = selectWebsocketRoom(state$.value);

      return of({
        type: 'JOIN_ROOM',
        meta: {
          room,
        },
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_WEBSOCKET_CONNECT_JOIN_ROOM',
          payload: err,
        }),
        source$,
      ),
    ),
  );
