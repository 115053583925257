/**
 * Labstep
 *
 * @module constants/icons
 * @desc Holds all icon relationships
 */

import { SemanticICONS } from 'semantic-ui-react';

export const iconPopups = {
  pencil: 'Edit',
  times: 'Delete',
  trash: 'Delete',
  setting: 'Settings',
  question: 'Help',
  'sign-out': 'Sign Out',
  attach: 'Attach File',
  hdd: 'Attach Folder Watcher File',
  dna: 'Attach SnapGene/GenBank File (.dna, .gb, .gbk)',
  table: 'Attach Table',
  image: 'Attach Image',
  'bell outline': 'Notifications',
  'cloud download': 'Download',
  external: 'Preview',
  'user plus': 'Invite',
  'paint brush': 'Annotate',
  exclamation: 'Urgent',
  'share alternate': 'Share',
  'check circle outline': 'Mark as complete',
  'check circle': 'Mark as incomplete',
  history: 'Activity Log',
};

export interface IEntityIconsProps {
  primary: SemanticICONS;
  secondary?: SemanticICONS;
}

interface IEntityStateWorkflowIcons extends IEntityIconsProps {
  actions: { lock: SemanticICONS; unlock: SemanticICONS };
  settings: SemanticICONS;
}
const entityStateWorkflowIcons: IEntityStateWorkflowIcons = {
  primary: 'code branch',
  actions: {
    lock: 'lock',
    unlock: 'unlock',
  },
  settings: 'settings',
};
interface IExperimentWorkflowIcons extends IEntityIconsProps {
  actions: { lock: SemanticICONS; unlock: SemanticICONS };
  info: {
    completed: SemanticICONS;
    started: SemanticICONS;
    unstarted: SemanticICONS;
  };
  template: SemanticICONS;
}
const entityViewIcons: IEntityIconsProps = {
  secondary: 'window maximize',
  primary: 'clipboard',
};
const experimentWorfklowIcons: IExperimentWorkflowIcons = {
  primary: 'book',
  template: 'copy',
  actions: {
    lock: 'lock',
    unlock: 'unlock',
  },
  info: {
    completed: 'check circle',
    started: 'adjust',
    unstarted: 'circle outline',
  },
};
interface ISignatureIcons extends IEntityIconsProps {
  actions: { lock: SemanticICONS };
}
const signatureIcons: ISignatureIcons = {
  primary: 'lock',
  actions: {
    lock: 'lock',
  },
};

interface IProtocolCollectionProps extends IEntityIconsProps {
  info: {
    draft: SemanticICONS;
    live: SemanticICONS;
  };
}

const protocolCollectionIcons: IProtocolCollectionProps = {
  primary: 'tasks',
  info: {
    draft: 'edit outline',
    live: 'check',
  },
};

interface IProtocolIcons {
  primary: SemanticICONS;
  step: SemanticICONS;
  value: SemanticICONS;
  timer: SemanticICONS;
  metadata: SemanticICONS;
  table: SemanticICONS;
}

const protocolIcons: IProtocolIcons = {
  primary: protocolCollectionIcons.primary,
  step: 'check circle',
  value: 'flask',
  timer: 'clock',
  metadata: 'database',
  table: 'table',
};

interface IExperimentIcons {
  primary: SemanticICONS;
  locked: SemanticICONS;
}

const experimentIcons: IExperimentIcons = {
  primary: 'tasks',
  locked: 'lock',
};

interface IResourceIconsProps extends IEntityIconsProps {
  info: {
    available: SemanticICONS;
    unavailable: SemanticICONS;
    custom_identifier: SemanticICONS;
  };
}
const resourceIcons: IResourceIconsProps = {
  primary: 'flask',
  info: {
    available: 'boxes',
    unavailable: 'ban',
    custom_identifier: 'crosshairs',
  },
};

const resourceTemplateIcons: IEntityIconsProps = {
  primary: 'box',
};

const templateIcons: IEntityIconsProps = {
  primary: 'box',
};

interface IResourceItemIconProps extends IEntityIconsProps {
  stock_check: SemanticICONS;
  info: {
    quantity: 'thermometer half';
    qr_code: 'qrcode';
    available: 'check';
    unavailable: 'ban';
  };
}

const resourceItemIcons: IResourceItemIconProps = {
  primary: 'boxes',
  stock_check: 'check circle',
  info: {
    quantity: 'thermometer half',
    qr_code: 'qrcode',
    available: 'check',
    unavailable: 'ban',
  },
};

interface IResourceLocationIconsProps extends IEntityIconsProps {
  stock_check: 'clipboard check';
  info: {
    map: SemanticICONS;
  };
}

const resourceLocationIcons: IResourceLocationIconsProps = {
  primary: 'point',
  stock_check: 'clipboard check',
  info: {
    map: 'th',
  },
};

interface IPurchaseOrderIconsProps extends IEntityIconsProps {
  info: {
    status: SemanticICONS;
  };
  actions: {
    lock: 'lock';
    unlock: 'unlock';
  };
}
const purchaseOrderIcons: IPurchaseOrderIconsProps = {
  primary: 'truck',
  info: {
    status: 'circle',
  },
  actions: {
    lock: 'lock',
    unlock: 'unlock',
  },
};

interface OrderRequestIconsProps extends IEntityIconsProps {
  info: {
    status: SemanticICONS;
    urgent: SemanticICONS;
    price: SemanticICONS;
  };
}
const orderRequestIcons: OrderRequestIconsProps = {
  primary: 'dolly flatbed',
  info: {
    status: 'circle',
    urgent: 'attention',
    price: 'money bill alternate',
  },
};

const signatureRequestIcons: IEntityIconsProps = {
  primary: 'edit outline',
};

interface IFileIcons extends IEntityIconsProps {
  actions: {
    annotate: SemanticICONS;
    delete: SemanticICONS;
    download: SemanticICONS;
    preview: SemanticICONS;
  };
}

const fileIcons: IFileIcons = {
  primary: 'file alternate',
  actions: {
    annotate: 'paint brush',
    delete: 'remove',
    download: 'download',
    preview: 'external alternate',
  },
};

const integrationIcons: IEntityIconsProps = {
  primary: 'plug',
};

const threadIcons: IEntityIconsProps = {
  primary: 'comments',
};

interface ITagIcons extends IEntityIconsProps {
  info: { untagged: SemanticICONS };
}
const tagIcons: ITagIcons = {
  primary: 'tag',
  info: {
    untagged: 'inbox',
  },
};

interface IGroupIcons extends IEntityIconsProps {
  actions: { share: 'user plus' };
  info: { home: SemanticICONS };
}

const groupIcons: IGroupIcons = {
  primary: 'briefcase',
  actions: {
    share: 'user plus',
  },
  info: {
    home: 'home',
  },
};

type IInstruemntIcons = IEntityIconsProps;
const deviceIcons: IInstruemntIcons = {
  primary: 'plug',
};

interface ICommentIcons
  extends Pick<
    IEntityIconsProps,
    Exclude<keyof IEntityIconsProps, 'primary'>
  > {
  actions: {
    pin: SemanticICONS;
  };
}
const commentIcons: ICommentIcons = {
  actions: {
    pin: 'pin',
  },
};

interface IFolderIcons
  extends Pick<
    IEntityIconsProps,
    Exclude<keyof IEntityIconsProps, 'actions'>
  > {
  info: {
    unselected: SemanticICONS;
  };
}

const folderIcons: IFolderIcons = {
  primary: 'folder open',
  info: {
    unselected: 'folder',
  },
};

interface IEntityIcons
  extends Pick<
    IEntityIconsProps,
    Exclude<keyof IEntityIconsProps, 'primary'>
  > {
  actions: {
    tag: SemanticICONS;
    create: SemanticICONS;
    lock: SemanticICONS;
    unlock: SemanticICONS;
    add: SemanticICONS;
    edit: SemanticICONS;
    revoke: SemanticICONS;
    assign: SemanticICONS;
    unassign: SemanticICONS;
    remove: SemanticICONS;
    sort: SemanticICONS;
  };
  info: {
    id: SemanticICONS;
    name: SemanticICONS;
  };
}
const entityIcons: IEntityIcons = {
  actions: {
    lock: 'lock',
    unlock: 'unlock',
    tag: 'tag',
    create: 'plus',
    remove: 'times',
    edit: 'pencil',
    add: 'plus',
    revoke: 'remove',
    assign: 'user plus',
    unassign: 'user cancel',
    sort: 'sort',
  },
  info: {
    id: 'hashtag',
    name: 'font',
  },
};

interface IEntityImportIcons extends IEntityIconsProps {
  revert: SemanticICONS;
}

const entityImportIcons: IEntityImportIcons = {
  primary: 'cloud upload',
  revert: 'undo',
};

const accessKeyIcons: IEntityIconsProps = {
  primary: 'wrench',
};

const paperIcons: IEntityIconsProps = {
  primary: 'file alternate',
};

const navigationIcons: { settings: SemanticICONS } = {
  settings: 'cog',
};

const infoIcons: {
  collapsed: SemanticICONS;
  expanded: SemanticICONS;
} = {
  collapsed: 'chevron down',
  expanded: 'chevron up',
};

interface MetadataIcons {
  primary: SemanticICONS;
  default: SemanticICONS;
  secondary: SemanticICONS;
  type: {
    default: SemanticICONS;
    numeric: SemanticICONS;
    date: SemanticICONS;
    datetime: SemanticICONS;
    options: SemanticICONS;
    file: SemanticICONS;
    molecule: SemanticICONS;
    sequence: SemanticICONS;
    entity_relation: SemanticICONS;
  };
}

const metadataIcons: MetadataIcons = {
  primary: 'database',
  default: 'database',
  secondary: 'bookmark',
  type: {
    default: 'align left',
    numeric: 'hashtag',
    date: 'calendar alternate',
    datetime: 'calendar alternate',
    options: 'list ul',
    file: 'file',
    molecule: 'react',
    sequence: 'dna',
    entity_relation: 'linkify',
  },
};

const userIcons: IEntityIconsProps = {
  primary: 'user',
  secondary: 'users',
};

interface IProtocolValueIconsProps extends IEntityIconsProps {
  info: {
    amount_used: 'sort amount down';
    amount_created: 'sort amount up';
    unit: 'subscript';
  };
}

const protocolValueIcons: IProtocolValueIconsProps = {
  primary: 'flask',
  info: {
    amount_used: 'sort amount down',
    amount_created: 'sort amount up',
    unit: 'subscript',
  },
};

const filterIcons: IEntityIconsProps = {
  primary: 'filter',
};

const organizationIcons: IEntityIconsProps = {
  primary: 'sitemap',
};

const upgradeIcons: IEntityIconsProps = {
  primary: 'arrow alternate circle up',
};

const moleculeIcons: IEntityIconsProps = {
  primary: 'react',
};

interface JupyterInstanceIconsProps {
  status: {
    success: SemanticICONS;
    error: SemanticICONS;
  };
}
const jupyterInstanceIcons: JupyterInstanceIconsProps = {
  status: {
    success: 'check',
    error: 'exclamation triangle',
  },
};

interface IProtocolConditionIconsProps extends IEntityIconsProps {
  info: {
    inputs: 'sign-in';
    outputs: 'sign-out';
    variable: 'sliders';
  };
}

const protocolConditionIcons: IProtocolConditionIconsProps = {
  primary: 'random',
  secondary: 'binoculars',
  info: {
    inputs: 'sign-in',
    outputs: 'sign-out',
    variable: 'sliders',
  },
};

interface AGGridIconsProps {
  delete_row: SemanticICONS;
}

const agGridIcons: AGGridIconsProps = {
  delete_row: 'remove',
};

export const ICONS = {
  access_key: accessKeyIcons,
  ag_grid: agGridIcons,
  comment: commentIcons,
  device: deviceIcons,
  entity: entityIcons,
  entity_import: entityImportIcons,
  entity_view: entityViewIcons,
  entity_state_workflow: entityStateWorkflowIcons,
  experiment: experimentIcons,
  experiment_workflow: experimentWorfklowIcons,
  file: fileIcons,
  filter: filterIcons,
  folder: folderIcons,
  group: groupIcons,
  info: infoIcons,
  integration: integrationIcons,
  jupyter_instance: jupyterInstanceIcons,
  navigation: navigationIcons,
  metadata: metadataIcons,
  molecule: moleculeIcons,
  order_request: orderRequestIcons,
  organization: organizationIcons,
  paper: paperIcons,
  purchase_order: purchaseOrderIcons,
  protocol: protocolIcons,
  protocol_condition: protocolConditionIcons,
  protocol_collection: protocolCollectionIcons,
  protocol_value: protocolValueIcons,
  resource: resourceIcons,
  resource_item: resourceItemIcons,
  resource_location: resourceLocationIcons,
  resource_template: resourceTemplateIcons,
  template: templateIcons,
  signature: signatureIcons,
  signature_request: signatureRequestIcons,
  tag: tagIcons,
  thread: threadIcons,
  user: userIcons,
  upgrade: upgradeIcons,
};
