/**
 * Labstep
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { updateAmountActionComponentProps } from 'labstep-web/components/ProtocolValue/Action/UpdateAmount/constants';
import { AmountUpdate } from 'labstep-web/models/amount-update.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import {
  evaluateProtocolValue,
  getValidProtocolValues,
} from 'labstep-web/services/protocol-value.service';
import lodash from 'lodash';
import { IProtocolValueUpdateAmountBulkActionProps } from './types';

export const ProtocolValueUpdateAmountBulkAction: React.FC<
  IProtocolValueUpdateAmountBulkActionProps
> = ({ protocolValues: values, setRefreshKey }) => {
  const variableTemplates = values.filter(
    (p) =>
      p.is_variable &&
      p.is_input &&
      p.protocol_value_template_children.length > 0,
  );

  // Get all the children of the variable templates that can be updated
  const variableTemplateChildren = variableTemplates.flatMap(
    (variableTemplate) =>
      variableTemplate.protocol_value_template_children.filter(
        (child) => {
          const { canUpdate } = evaluateProtocolValue(child);
          return canUpdate;
        },
      ),
  );

  const uniqueProtocolValues = lodash.uniqBy(
    [...values, ...variableTemplateChildren],
    'id',
  );

  const entityIdsAccessObject = useHasAccessMultiple(
    ProtocolValue.entityName,
    uniqueProtocolValues ? uniqueProtocolValues.map((p) => p.id) : [],
    Action.edit,
    'resource_item',
  );

  const canBulkEdit = uniqueProtocolValues.every(
    (protocolValue) =>
      entityIdsAccessObject[protocolValue.id] === true,
  );

  if (!canBulkEdit) {
    return null;
  }

  const protocolValues = uniqueProtocolValues
    .filter((p) => p.is_input && !p.is_variable)
    .map((value) => {
      const { canUpdate } = evaluateProtocolValue(value);

      if (canUpdate) {
        return value;
      }
    })
    .filter((value) => value !== undefined);

  const items = getValidProtocolValues(protocolValues).map(
    (value) => ({
      protocol_value_id: value.protcolValueId,
    }),
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <EntityActionCreate
      entityName={AmountUpdate.entityName}
      batch
      body={items as any}
      actionComponentProps={{
        ...updateAmountActionComponentProps,
        text: 'Update Amount Remaining for All Items',
      }}
      options={{
        onSuccess: () => {
          setRefreshKey();
        },
      }}
      skipPermissionCheck
    />
  );
};
