/**
 * Labstep
 *
 * @module services/protocol-value
 * @desc A service to deal with protocol value
 */

import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import {
  canUpdateAmount,
  checkUnitBase,
  getAmounts,
  isAmountUpdated,
} from 'labstep-web/services/amount-unit.service';
import { StrictIconProps } from 'semantic-ui-react/dist/commonjs/elements/Icon';

export const evaluateProtocolValue = (
  protocolValue: ProtocolValue,
) => {
  const errorMessages = {
    noItem: 'Please select item',
    noAmount: 'Amount used is not specified',
    noItemAmount: 'Item Amount Remaining Unspecified',
    noItemUnit: 'Item Unit Unspecified',
    noUnit: 'Amount used Unit Unspecified',
    mismatchingUnits:
      'Mismatching units between the item and the inventory field',
    insufficientAmount: 'Insufficient Amount Remaining',
    updatedAmount: 'Amount used deducted from item',
    readyToUpdate: 'Click to update amount remaining',
  };

  let canUpdate = false;
  let content = '';
  let iconName: StrictIconProps['name'] = 'warning sign';
  let iconColor: StrictIconProps['color'] = 'orange';

  if (!protocolValue.resource_item) {
    content = errorMessages.noItem;
  } else if (!protocolValue.amount) {
    content = errorMessages.noAmount;
  } else if (!protocolValue.resource_item.amount) {
    content = errorMessages.noItemAmount;
  } else if (!protocolValue.resource_item.unit) {
    content = errorMessages.noItemUnit;
  } else if (!protocolValue.unit) {
    content = errorMessages.noUnit;
  } else if (
    !checkUnitBase(
      protocolValue.unit || '',
      protocolValue.resource_item.unit || '',
    )
  ) {
    content = errorMessages.mismatchingUnits;
  } else if (isAmountUpdated(protocolValue)) {
    iconName = 'check circle';
    iconColor = 'green';
    content = errorMessages.updatedAmount;
  } else if (!canUpdateAmount(protocolValue)) {
    content = errorMessages.insufficientAmount;
  } else {
    canUpdate = true;
    iconName = 'check circle';
    iconColor = 'yellow';
    content = errorMessages.readyToUpdate;
  }

  return {
    canUpdate,
    content,
    iconName,
    iconColor,
  };
};

export const getValidProtocolValues = (
  protocolValues: ProtocolValue[],
) => {
  const values = protocolValues
    .map((value) => {
      const { amountTobeUpdated, resourceItemAmount } =
        getAmounts(value);
      if (amountTobeUpdated !== null && resourceItemAmount !== null) {
        return {
          protcolValueId: value.guid,
          resourceItemId: value.resource_item!.guid,
          amountTobeUpdated,
          resourceItemAmount,
        };
      }
    })
    .filter((value) => value !== undefined);

  const resourceItemAmounts = values.reduce((map, item) => {
    if (!map.has(item.resourceItemId)) {
      map.set(item.resourceItemId, item.resourceItemAmount);
    }
    return map;
  }, new Map<string, number>());
  // Filter the values based on the remaining amounts in resourceItem
  const result = values.filter((item) => {
    const remainingAmount =
      resourceItemAmounts.get(item.resourceItemId) || 0;

    if (item.amountTobeUpdated <= remainingAmount) {
      // Deduct the item's amount from the remaining resourceItem amount
      resourceItemAmounts.set(
        item.resourceItemId,
        remainingAmount - item.amountTobeUpdated,
      );
      return true; // Keep the item
    }

    // Exclude the item if there's not enough remaining resourceItem amount
    return false;
  });

  return result;
};
