/**
 * Labstep
 *
 * @module prosemirror/nodes/table
 * @desc Table node
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { TableNodes, tableNodes } from 'prosemirror-tables';
import plugins from './plugins';

const nodes: TableNodes = tableNodes({
  tableGroup: 'block',
  cellContent: 'block+',
  cellAttributes: {
    background: {
      default: null,
      getFromDOM(dom) {
        if (dom instanceof HTMLElement) {
          return dom.style.backgroundColor;
        }
        return null;
      },
      setDOMAttr(value, attrs) {
        if (value) {
          // eslint-disable-next-line no-param-reassign
          attrs.style = `${
            attrs.style || ''
          }background-color: ${value};`;
        }
      },
    },
  },
});

const NodeTable: LabstepNode = {
  key: 'table',
  spec: nodes.table,
  plugins,
};

const NodeTableCell: LabstepNode = {
  key: 'table_cell',
  spec: nodes.table_cell,
};

const NodeTableHeader: LabstepNode = {
  key: 'table_header',
  spec: nodes.table_header,
};

const NodeTableRow: LabstepNode = {
  key: 'table_row',
  spec: nodes.table_row,
};

export { NodeTable, NodeTableCell, NodeTableHeader, NodeTableRow };
