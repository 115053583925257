/**
 * Labstep
 *
 * TODO Refactor into a metadata service
 */

import { MetadataType } from 'labstep-web/models/metadata/types';
import { Resource } from 'labstep-web/models/resource.model';
import {
  getFieldsByType,
  mapArrayOfStringToOptionValues,
} from './utils';

/* System fields */
export const METADATA_SYSTEM_FIELDS = [
  'id',
  'created_at',
  'updated_at',
  'deleted_at',
  'locked_at',
  'position',
  'thread',
  'thread_id',
  'metadata_thread',
  'metadata_thread_id',
  'data_table',
  'rich_text',
];

/**
 * Convert type field from option to string value.
 */
export const preSubmitType = (values: any): any => {
  if (values.type && values.type.value) {
    values.type = values.type.value;
  }
};

/**
 * Only send fields corresponding to type.
 */
export const preSubmitFieldsByType = (values: any): any => {
  if (values.type) {
    const fieldsToReset = getFieldsByType(
      values.type,
      'fieldsToReset',
    );
    const fieldsToDelete = getFieldsByType(
      values.type,
      'fieldsToDelete',
    );
    Object.keys(values).forEach((key) => {
      if (fieldsToReset.indexOf(key) !== -1) {
        values[key] = null;
      }
    });
    Object.keys(values).forEach((key) => {
      if (fieldsToDelete.indexOf(key) !== -1) {
        delete values[key];
      }
    });
  }
};

/**
 * Don't send invalid dates.
 */
export const preSubmitSkipInvalidDate = (values: any): any => {
  if (values.date === 'Invalid date') {
    values.date = null;
  }
};

/**
 * Merge metadata options values into a single JSON object.
 */
export const preSubmitOptions = (values: any): any => {
  if (
    Object.keys(values).includes('options_values') ||
    Object.keys(values).includes('options_is_allow_multiple') ||
    Object.keys(values).includes('options_is_allow_add')
  ) {
    values.options = {
      values: {},
      is_allow_multiple: values.options_is_allow_multiple || false,
      is_allow_add: values.options_is_allow_add || false,
    };
    if (Object.keys(values).includes('options_values')) {
      // Edit mode
      if (values.options_selected_values) {
        values.options.values = mapArrayOfStringToOptionValues(
          values.options_selected_values,
          values.options_values,
        );
        delete values.options_selected_values;
      } else {
        // Create mode. Initialise all with false;
        values.options.values = mapArrayOfStringToOptionValues(
          [],
          values.options_values,
        );
      }

      delete values.options_values;
    }
    if (Object.keys(values).includes('options_is_allow_multiple')) {
      delete values.options_is_allow_multiple;
    }
    if (Object.keys(values).includes('options_is_allow_add')) {
      delete values.options_is_allow_add;
    }
  }
};

/**
 * Remove system fields.
 */
export const preSubmitSystemFields = (values: any): any => {
  Object.keys(values).forEach((key) => {
    if (METADATA_SYSTEM_FIELDS.indexOf(key) !== -1) {
      delete values[key];
    }
  });
};

export const preSubmitEntityRelation = (values: any) => {
  if (values.type === MetadataType.entity_relation) {
    values.field_definition = {
      name: values.label,
      data_type: MetadataType.entity_relation,
      entity_relation_type: values.entity_relation_type
        ? values.entity_relation_type.replace(' ', '_').toLowerCase()
        : 'one_way',
      entity_relation_target_entity_class: Resource.entityName,
      group_id: values.group_id,
      is_multiple_entity_relations_allowed:
        values.is_multiple_entity_relations_allowed,
    };

    if (
      Object.keys(values).includes(
        'entity_relation_target_entity_filter_template_id',
      )
    ) {
      values.field_definition.entity_relation_target_entity_filter = {
        template_id:
          values.entity_relation_target_entity_filter_template_id,
      };
    }
    delete values.is_multiple_entity_relations_allowed;
    delete values.entity_relation_target_entity_filter_template_id;
    delete values.entity_relation_type;
    delete values.backlink_name;
    delete values.current_entity;
  }
};

/**
 * Convert values before submitting form.
 */
export const preSubmit = (values: any): any => {
  const newValues = { ...values };
  preSubmitType(newValues);
  preSubmitSkipInvalidDate(newValues);
  preSubmitOptions(newValues);
  preSubmitFieldsByType(newValues);
  preSubmitEntityRelation(newValues);
  preSubmitSystemFields(newValues);

  return newValues;
};
