/**
 * Labstep
 *
 * @module components/Metadata/Form/fields
 * @desc Form Fields for Metadata
 */

import { Action } from 'labstep-web/components/Entity/Can/types';
import { FieldDefinitionFormLinkTypePreview } from 'labstep-web/components/FieldDefinition/Form/LinkTypePreview';
import { RelationTypeButtonContent } from 'labstep-web/components/FieldDefinition/Form/RelationTypeButtonContent';
import {
  FieldActionProps,
  FieldType,
  FieldWithoutActionProps,
  IValues,
} from 'labstep-web/core/Form/Reusable/types';
import { EntityRelationType } from 'labstep-web/models/field-definition.model';
import { Metadata } from 'labstep-web/models/metadata';
import {
  METADATA_TYPES,
  METADATA_TYPES_NOT_LINKED,
} from 'labstep-web/models/metadata/constants';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { Resource } from 'labstep-web/models/resource.model';
import rules from 'labstep-web/services/validation/rules';
import { UseFormReturn } from 'react-hook-form';
import { object } from 'yup';

export const FIELD_METADATA_LABEL: FieldWithoutActionProps = {
  fieldType: FieldType.Text,
  name: 'label',
  placeholder:
    'Enter the name of the relation i.e. parent, child, related...',
  fieldLabel: 'Field Name',
  validation: rules.metadata.label,
};

export const FIELD_METADATA_IS_REQUIRED: FieldWithoutActionProps = {
  name: 'is_required',
  fieldLabel: 'Required',
  fieldType: FieldType.Checkbox,
  validation: rules.metadata.is_required,
};

export const FIELD_METADATA_TYPE: FieldWithoutActionProps = {
  name: 'type',
  fieldLabel: 'Type',
  fieldType: FieldType.ReactSelect,
  validation: rules.metadata.type,
  elementProps: {
    options: METADATA_TYPES.map((metadataType) => ({
      label: metadataType.label,
      value: metadataType.value,
    })),
  },
};

export const FIELD_METADATA_TYPE_NOT_LINKED: FieldWithoutActionProps =
  {
    name: 'type',
    fieldLabel: 'Type',
    fieldType: FieldType.ReactSelect,
    validation: rules.metadata.type,
    elementProps: {
      options: METADATA_TYPES_NOT_LINKED.map((metadataType) => ({
        label: metadataType.label,
        value: metadataType.value,
      })),
    },
  };

/* Return placeholder based on isTemplate */
export const getPlaceholder = (
  field: string,
  isTemplate?: boolean,
): string => `Enter${isTemplate ? ` default` : ''} ${field}`;

/* Return field label based on isTemplate */
export const getFieldLabel = (
  field: string,
  isTemplate?: boolean,
): string => `${isTemplate ? `Default ` : ''}${field}`;

/* Return field value definition */
export const getFieldsValue = (
  isTemplate?: boolean,
): FieldWithoutActionProps[] => [
  {
    name: 'value',
    placeholder: getPlaceholder('value', isTemplate),
    fieldLabel: getFieldLabel('Value', isTemplate),
    fieldType: FieldType.Text,
    validation: rules.metadata.value,
  },
];

/* Return field date definition */
export const getFieldsDate = (
  isTemplate?: boolean,
): FieldWithoutActionProps[] => [
  {
    name: 'date',
    placeholder: getPlaceholder('date', isTemplate),
    fieldLabel: getFieldLabel('Date', isTemplate),
    fieldType: FieldType.DateTimePicker,
    validation: rules.metadata.date,
  },
];

/* Return field datetime definition */
export const getFieldsDatetime = (
  isTemplate?: boolean,
): FieldWithoutActionProps[] => [
  {
    name: 'date',
    placeholder: getPlaceholder('date', isTemplate),
    fieldLabel: getFieldLabel('Date and time', isTemplate),
    fieldType: FieldType.DateTimePicker,
    validation: rules.metadata.date,
    elementProps: {
      enableTime: true,
    },
  },
];

export const fieldUnit: FieldWithoutActionProps = {
  name: 'unit',
  placeholder: 'unit',
  fieldLabel: 'Unit',
  fieldType: FieldType.Text,
  validation: rules.metadata.unit,
  elementProps: {
    withSymbols: true,
  },
};

/* Return fields numeric definition */
export const getFieldsNumeric = (
  isTemplate?: boolean,
): FieldWithoutActionProps[] => [
  {
    name: 'number',
    placeholder: getPlaceholder('number', isTemplate),
    fieldLabel: getFieldLabel('Value', isTemplate),
    fieldType: FieldType.Text,
    validation: rules.metadata.number,
  },
  {
    name: 'unit',
    placeholder: getPlaceholder('unit', isTemplate),
    fieldLabel: getFieldLabel('Unit', isTemplate),
    fieldType: FieldType.Text,
    validation: rules.metadata.unit,
    elementProps: {
      withSymbols: true,
    },
  },
];

/* Return fields options definition */
export const getFieldsOptions = (
  metadata?: Metadata,
  inline?: boolean,
  isTemplate?: boolean,
  blurOnUnmount?: boolean,
): FieldWithoutActionProps[] => {
  if (metadata && inline) {
    const options = metadata.optionsTemplate
      ? metadata.optionsTemplate.options
      : metadata.options;
    return [
      {
        placeholder: getPlaceholder('options', isTemplate),
        name: 'options_values',
        fieldLabel: isTemplate
          ? getFieldLabel('Options', isTemplate)
          : undefined,
        fieldType: FieldType.SelectOptions,
        validation: rules.metadata.options_values,
        elementProps: {
          multiple: !options ? false : options.is_allow_multiple,
          allowAdd: !options ? false : options.is_allow_add,
          blurOnUnmount,
        },
      },
    ];
  }
  return [
    {
      placeholder: getPlaceholder('options', isTemplate),
      fieldLabel: getFieldLabel('Options', isTemplate),
      name: 'options_values',
      fieldType: FieldType.SelectMulti,
      validation: rules.metadata.options_values,
    },
    {
      name: 'options_is_allow_multiple',
      fieldLabel: 'Allow multiple options',
      fieldType: FieldType.Checkbox,
      validation: rules.metadata.options_is_allow_multiple,
    },
    {
      name: 'options_is_allow_add',
      fieldLabel: 'Allow adding new options',
      fieldType: FieldType.Checkbox,
      validation: rules.metadata.options_is_allow_add,
      elementProps: {
        defaultValue: true,
      },
    },
  ];
};

/* Return field file definition */
export const getFieldsFile = (
  isTemplate?: boolean,
): FieldWithoutActionProps[] => [
  {
    fieldLabel: getFieldLabel('File', isTemplate),
    placeholder: `Upload ${isTemplate ? 'default' : ''} file`,
    name: 'files',
    fieldType: FieldType.File,
    validation: rules.metadata.file,
    elementProps: {
      multiple: true,
    },
  },
];

/* Return field entity link definition */
export const getFieldsEntityLink = (
  values: any,
  isTemplate?: boolean,
): (FieldWithoutActionProps | FieldActionProps)[] => {
  const fields: (FieldWithoutActionProps | FieldActionProps)[] = [];
  if (isTemplate) {
    fields.push({
      fieldLabel: `Select Category`,
      name: 'entity_relation_target_entity_filter_template',
      validation: object().required(),
      fieldType: FieldType.SearchSelect,
      elementProps: {
        searchKey: 'search_query',
        entityName: Resource.entityName,
        params: {
          is_template: true,
        },
      },
    });
  }

  const template_id =
    values?.field_definition?.entity_relation_target_entity_filter
      ?.template_id;

  const canEditProps = template_id
    ? {
        entityName: Resource.entityName,
        entityId: template_id,
        action: Action.edit,
      }
    : undefined;

  fields.push(
    {
      name: 'is_multiple_entity_relations_allowed',
      fieldLabel: 'Multiselect',
      fieldType: FieldType.Checkbox,
      validation:
        rules.field_definition.is_multiple_entity_relations_allowed,
    },
    {
      name: 'entity_relation_type',
      fieldLabel: 'Relation type',
      fieldType: FieldType.Radio,
      validation: rules.field_definition.relation_type,
      elementProps: {
        items: Object.values(EntityRelationType).map(
          (entityRelationType) => {
            return {
              key: entityRelationType,
              label: entityRelationType,
              name: entityRelationType,
              value: entityRelationType,
              component: (
                <RelationTypeButtonContent
                  entityRelationType={entityRelationType}
                />
              ),
              disableException:
                entityRelationType === EntityRelationType.one_way,
            };
          },
        ),
        isCustom: true,
        defaultValue: EntityRelationType.one_way,
        disabled: {
          editProps: canEditProps,
          isDisabled: template_id === undefined,
          message: template_id
            ? `You do not have permission to add a relation to the category`
            : 'Must select category first',
        },
      },
    },
    {
      name: 'entity_relation_type',
      fieldLabel: 'Relation type',
      fieldType: FieldType.action,
      validation: rules.field_definition.relation_type,
      component: ({
        values,
        reset,
      }: {
        values: Record<string, unknown>;
        reset: UseFormReturn<any>['reset'];
      }) => (
        <FieldDefinitionFormLinkTypePreview
          entityName={Resource.entityName}
          relationType={
            values.entity_relation_type as EntityRelationType
          }
          relationName={values.label as string}
          backLinkName={
            values.entity_relation_type ===
              EntityRelationType.asymmetric && values.backlink_name
              ? (values.backlink_name as string)
              : 'backlink'
          }
        />
      ),
    },
  );

  if (
    values?.field_definition?.entity_relation_type ===
    `${EntityRelationType.asymmetric.toLocaleLowerCase()}`
  ) {
    fields.push({
      fieldType: FieldType.Text,
      name: 'backlink_name',
      placeholder: 'Enter the name of inverse relation',
      fieldLabel: 'Backlink Name',
      validation: rules.field_definition.backlink_name,
    });
  }
  return fields;
};

/**
 * Returns form fields based on metadata type.
 *
 * @param type          Metadata type
 * @param isTemplate    If true, it means the metadata parent is a template
 *                      between configuring it or returning the field to select options.
 * @param metadata      This variable is only used for metadata 'options'
 *                      to send configuration variables to the field via the 'selectOptions' key.
 */
export const getMetadataFormFieldsAll = (
  type: MetadataType | null,
  isTemplate?: boolean,
  metadata?: Metadata,
  noValue?: boolean,
  hideLinkedTypes?: boolean,
  formValues?: IValues,
): (FieldWithoutActionProps | FieldActionProps)[] => {
  let fields: (FieldWithoutActionProps | FieldActionProps)[] = [
    FIELD_METADATA_LABEL,
  ];
  if (!metadata) {
    fields.push(
      hideLinkedTypes
        ? FIELD_METADATA_TYPE_NOT_LINKED
        : FIELD_METADATA_TYPE,
    );
  }

  if (!noValue) {
    if (type === MetadataType.default) {
      fields = fields.concat(getFieldsValue(isTemplate));
    } else if (type === MetadataType.date) {
      fields = fields.concat(getFieldsDate(isTemplate));
    } else if (type === MetadataType.datetime) {
      fields = fields.concat(getFieldsDatetime(isTemplate));
    } else if (type === MetadataType.numeric) {
      fields = fields.concat(getFieldsNumeric(isTemplate));
    } else if (type === MetadataType.file) {
      fields = fields.concat(getFieldsFile(isTemplate));
    } else if (type === MetadataType.entity_relation) {
      fields = fields.concat(
        getFieldsEntityLink(formValues, isTemplate),
      );
    }
  }

  if (type === MetadataType.options) {
    fields = fields.concat(
      getFieldsOptions(metadata, false, isTemplate),
    );
  }

  if (isTemplate) {
    fields = [...fields, FIELD_METADATA_IS_REQUIRED];
  }

  return fields;
};

/**
 * Form fields (label only)
 */
export const getMetadataFormFieldsLabelOnly = (
  isTemplate?: boolean,
): FieldWithoutActionProps[] => {
  if (isTemplate) {
    return [FIELD_METADATA_LABEL, FIELD_METADATA_IS_REQUIRED];
  }

  return [FIELD_METADATA_LABEL];
};

/**
 * Returns label only if metadata is a file and parent is template
 */
export const getMetadataFormFields = (
  type: MetadataType | null,
  isTemplate?: boolean,
  metadata?: Metadata,
  noValue?: boolean,
  hideLinkedTypes?: boolean,
  formValues?: IValues,
) =>
  metadata && metadata.type === MetadataType.file && isTemplate
    ? getMetadataFormFieldsLabelOnly(isTemplate)
    : getMetadataFormFieldsAll(
        type,
        isTemplate,
        metadata,
        noValue,
        hideLinkedTypes,
        formValues,
      );
