/**
 * Labstep
 */

import { createBatch } from 'labstep-web/core/DataGrid/utils';
import Icon from 'labstep-web/core/Icon';
import { AmountUpdate } from 'labstep-web/models/amount-update.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { isAmountUpdated } from 'labstep-web/services/amount-unit.service';
import {
  evaluateProtocolValue,
  getValidProtocolValues,
} from 'labstep-web/services/protocol-value.service';
import React from 'react';
import styles from './styles.module.scss';
import { ResourceItemHeaderProps } from './types';

export const getProtocolValues = (props: ResourceItemHeaderProps) => {
  const { getNestedEntity, api } = props;
  const protocolValues: ProtocolValue[] = [];
  api.forEachNode((node) => {
    const entity = getNestedEntity
      ? getNestedEntity({
          ...props,
          data: node.data,
        })
      : undefined;
    if (entity) {
      if (entity.amount === null && entity.resourceItem === null) {
        return;
      }
      protocolValues.push(entity);
    }
  });
  return protocolValues;
};

export const HeaderComponent: React.FC<ResourceItemHeaderProps> = (
  props,
) => {
  const { displayName, icon, isInput, refresh } = props;

  const protocolValues = getProtocolValues(props);

  const amountsNotUpdated = protocolValues.some(
    (p) => !isAmountUpdated(p),
  );

  const circleIcon = (
    <Icon
      color={amountsNotUpdated ? 'yellow' : 'green'}
      name="check circle"
      data-testid="conditions_table_item_input"
      popup={{
        content: amountsNotUpdated
          ? 'Update all amounts'
          : 'All Amounts Updated',
        size: 'small',
      }}
      onClick={
        amountsNotUpdated
          ? () => {
              const filteredProtocolValues: ProtocolValue[] = [];
              protocolValues.forEach((protocolValue) => {
                const { canUpdate } =
                  evaluateProtocolValue(protocolValue);
                if (canUpdate) {
                  filteredProtocolValues.push(protocolValue);
                }
              });
              const validProtocolValues = getValidProtocolValues(
                filteredProtocolValues,
              );

              const items = validProtocolValues.map((value) => ({
                protocol_value_id: value.protcolValueId,
              }));

              if (items.length > 0) {
                createBatch({
                  entityName: AmountUpdate.entityName,
                  body: items,
                  options: {
                    onSuccess: () => {
                      refresh();
                    },
                  },
                });
              } else {
                refresh();
              }
            }
          : undefined
      }
    />
  );

  return (
    <span className={styles.columnHeader}>
      <span>
        <Icon name={icon} /> {displayName}
      </span>
      {isInput && protocolValues.length > 0 && circleIcon}
    </span>
  );
};
