import { ICONS } from 'labstep-web/constants/icons';
import Card from 'labstep-web/core/Card/Card';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import { EntityRelationType } from 'labstep-web/models/field-definition.model';
import { SemanticICONS } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { FieldDefinitionFormLinkTypePreviewProps } from './types';

export const FieldDefinitionFormLinkTypePreview: React.FC<
  FieldDefinitionFormLinkTypePreviewProps
> = ({
  entityName,
  relationType,
  relationName = '',
  backLinkName = '',
}) => {
  const backlink = backLinkName !== '' ? backLinkName : 'Backlink';
  const relation =
    relationName !== '' ? relationName : 'Relation Name';

  return (
    <Flex vAlignContent="center" hAlignContent="center" spacing="gap">
      <Card className={styles.preview}>
        <Flex column>
          <Flex>
            <Icon
              name={ICONS.resource.primary as SemanticICONS}
              size="large"
            />
            <span className={styles.name}>{entityName} A</span>
          </Flex>
          <Flex>
            <span className={styles.relation}>{relation} :</span>
            <span className={styles.subname}>
              <Icon
                name={ICONS.resource.primary as SemanticICONS}
                size="small"
              />
              {entityName} B
            </span>
          </Flex>
        </Flex>
      </Card>
      <Card className={styles.preview}>
        <Flex column>
          <Flex>
            <Icon
              name={ICONS.resource.primary as SemanticICONS}
              size="large"
            />
            <span className={styles.name}>{entityName} B</span>
          </Flex>
          {relationType !== EntityRelationType.one_way && (
            <Flex>
              <span className={styles.relation}>
                {relationType === EntityRelationType.asymmetric
                  ? backlink
                  : relation}
                :
              </span>
              <span className={styles.subname}>
                <Icon
                  name={ICONS.resource.primary as SemanticICONS}
                  size="small"
                />
                {entityName} A
              </span>
            </Flex>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};
