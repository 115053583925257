/**
 * Labstep
 *
 * @module screens/Device/Index/Template
 * @desc Device Index Template
 */

import DeviceActionCreate from 'labstep-web/components/Device/Action/Create';
import DeviceEmptyStateTemplate from 'labstep-web/components/Device/Card/EmptyState/Template';
import DeviceTemplateTable from 'labstep-web/components/DeviceTemplate/Table';
import EntitySearch from 'labstep-web/components/Entity/Search';
import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { Device } from 'labstep-web/models/device.model';
import { bulkActionsTemplate } from 'labstep-web/screens/Device/Index/constants';
import React, { useMemo } from 'react';
import { IDeviceIndexTemplateProps } from './types';

export const DeviceIndexTemplate: React.FC<
  IDeviceIndexTemplateProps
> = ({ group }) => {
  const params = useMemo(
    () => ({
      group_id: group.id,
      is_template: true,
    }),
    [group.id],
  );

  const action = useMemo(() => <DeviceActionCreate isTemplate />, []);
  const noResultsMessage = useMemo(
    () => <DeviceEmptyStateTemplate />,
    [],
  );

  return (
    <EntitySearch
      action={action}
      bulkActions={bulkActionsTemplate}
      filters={[Filter.deleted, Filter.created_by]}
      entityName={Device.entityName}
      noResultsMessage={noResultsMessage}
      params={params}
      tableFormat={false}
    >
      {({
        entities,
        ...rest
      }: EntitySearchContainerChildrenProps) => (
        <DeviceTemplateTable deviceTemplates={entities} {...rest} />
      )}
    </EntitySearch>
  );
};

export default DeviceIndexTemplate;
