/**
 * Labstep
 *
 * @module state/reducers/prosemirror-referencing-search
 * @desc Reducers for ProseMirror referencing search
 */

import { setProseMirrorReferencingSearchTypeName } from 'labstep-web/state/actions/prosemirror-referencing-search';
import { AnyAction } from 'redux';

const proseMirrorReferencingSearch = (
  state = null,
  action: AnyAction,
) => {
  if (
    action &&
    action.type === setProseMirrorReferencingSearchTypeName
  ) {
    return action.payload;
  }
  return state;
};

export default proseMirrorReferencingSearch;
