/**
 * Labstep
 *
 * @module screens/Resource/Show/Right
 * @desc Resource Show Right
 */

import ExperimentWorkflowLinkedList from 'labstep-web/components/ExperimentWorkflow/LinkedList';
import RightPane from 'labstep-web/components/Layout/RightPane';
import MetadataManagerList from 'labstep-web/components/Metadata/Manager/List';
import MetadataShow, {
  MetadataShowName,
} from 'labstep-web/components/Metadata/Show';
import ProtocolDeviceManager from 'labstep-web/components/ProtocolDevice/Manager';
import ProtocolStepManager from 'labstep-web/components/ProtocolStep/Manager';
import ProtocolTimerManager from 'labstep-web/components/ProtocolTimer/Manager';
import ProtocolValueManager from 'labstep-web/components/ProtocolValue/Manager';
import ProtocolValueShow, {
  ProtocolValueShowName,
} from 'labstep-web/components/ProtocolValue/Show';
import { ICONS } from 'labstep-web/constants/icons';
import { Protocol } from 'labstep-web/models/protocol.model';
import React from 'react';
import { IScreensProtocolShowRightProps } from './types';

const ScreensProtocolShowRight: React.FC<
  IScreensProtocolShowRightProps
> = ({ protocol }) => (
  <RightPane
    route={{
      to: 'protocol_show',
      params: { id: protocol.id },
    }}
    subPages={[
      {
        name: 'Steps',
        icon: ICONS.protocol.step,
        count: protocol.protocol_step_count,
        route: {
          to: 'protocol_show_steps',
          params: { id: protocol.id },
        },
        component: <ProtocolStepManager protocol={protocol} />,
      },
      {
        name: 'Timers',
        icon: ICONS.protocol.timer,
        count: protocol.protocol_timer_count,
        route: {
          to: 'protocol_show_timers',
          params: { id: protocol.id },
        },
        component: <ProtocolTimerManager protocol={protocol} />,
      },
      {
        name: 'Inventory',
        icon: ICONS.resource.primary,
        count: protocol.protocol_value_count,
        route: {
          to: 'protocol_show_values',
          params: { id: protocol.id },
        },
        component: <ProtocolValueManager protocol={protocol} />,
      },
      {
        name: 'Data',
        icon: ICONS.metadata.primary,
        count: protocol.metadata_thread.metadata_count,
        route: {
          to: 'protocol_show_metadata',
          params: { id: protocol.id },
        },
        component: (
          <MetadataManagerList entity={protocol} isTemplate />
        ),
      },
      {
        name: 'Devices',
        icon: ICONS.device.primary,
        count: protocol.protocol_device_count,
        route: {
          to: 'protocol_show_devices',
          params: { id: protocol.id },
        },
        component: <ProtocolDeviceManager parent={protocol} />,
      },
      {
        name: 'Linked Experiments',
        icon: ICONS.experiment_workflow.primary,
        route: {
          to: 'protocol_show_experiments',
          params: { id: protocol.id },
        },
        component: (
          <ExperimentWorkflowLinkedList
            params={{ protocol_id: protocol.id }}
          />
        ),
      },
    ]}
    subPagesNested={[
      {
        icon: ICONS.resource.primary,
        route: {
          to: 'protocol_show_values_outputs',
          params: { id: protocol.id },
        },
        backRoute: {
          to: 'protocol_show',
          params: { id: protocol.id },
        },
        component: () => <ProtocolValueManager protocol={protocol} />,
        name: () => 'Inventory',
      },
      {
        icon: ICONS.protocol_value.primary,
        route: {
          to: 'protocol_show_value_show',
        },
        backRoute: {
          to: 'protocol_show_values',
          params: { id: protocol.id },
        },
        component: ({ match }) => (
          <ProtocolValueShow id={match.params.childId} />
        ),
        name: ({ match }) => (
          <ProtocolValueShowName id={match.params.childId} />
        ),
      },
      {
        icon: ICONS.protocol_value.primary,
        route: {
          to: 'protocol_show_value_show_output',
        },
        backRoute: {
          to: 'protocol_show_values_outputs',
          params: { id: protocol.id },
        },
        component: ({ match }) => (
          <ProtocolValueShow id={match.params.childId} />
        ),
        name: ({ match }) => (
          <ProtocolValueShowName id={match.params.childId} />
        ),
      },
      {
        icon: ICONS.metadata.primary,
        route: {
          to: 'protocol_show_metadata_show',
        },
        backRoute: {
          to: 'protocol_show_metadata',
          params: { id: protocol.id },
        },
        component: ({ match }) => (
          <MetadataShow
            id={match.params.childId}
            parentEntityName={Protocol.entityName}
          />
        ),
        name: ({ match }) => (
          <MetadataShowName id={match.params.childId} />
        ),
      },
    ]}
  />
);

export default ScreensProtocolShowRight;
