/**
 * Labstep
 *
 * @module constants/fos-js-routes
 * @desc Symfony FOS JS Routing dump
 *
 * To update Symfony routes follow these steps:
 * - Run "php bin/console fos:js-routing:dump"
 * - Open "php/monolith/public/js/fos_js_routes.js"
 * - Extract the JSON string inside fos.Router.setData([JSON]);
 * - Paste the result here
 * This should not trigger any lint error and display nicely vertically.
 * This will make git diff easier to see added/removed routes.
 */

export const fosJsRoutesData = {
  base_url: '',
  routes: {
    app_api_accesskey_getaccesskeys: {
      tokens: [['text', '/api/generic/access-key']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_accesskey_postaccesskey: {
      tokens: [['text', '/api/generic/access-key']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_accesskey_putaccesskey: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/access-key'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_amountupdate_getamountupdates: {
      tokens: [['text', '/api/generic/amount-update']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_amountupdate_postamountupdate: {
      tokens: [['text', '/api/generic/amount-update']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_amountupdate_postbatchamountupdate: {
      tokens: [['text', '/api/generic/amount-update/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_amountupdate_putamountupdate: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/amount-update'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_automation_getautomations: {
      tokens: [['text', '/api/generic/automation']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_automation_postautomation: {
      tokens: [['text', '/api/generic/automation']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_automation_putautomation: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/automation'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_chemical_getchemicals: {
      tokens: [['text', '/api/generic/chemical']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_chemical_postchemical: {
      tokens: [['text', '/api/generic/chemical']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_chemical_postbatchchemical: {
      tokens: [['text', '/api/generic/chemical/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_chemical_putchemical: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/chemical'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_comment_getcomments: {
      tokens: [['text', '/api/generic/comment']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_comment_postcomment: {
      tokens: [['text', '/api/generic/comment']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_comment_putcomment: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/comment'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_customidentifierset_postcustomidentifierset: {
      tokens: [['text', '/api/generic/custom-identifier-set']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_customidentifierset_putcustomidentifierset: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/custom-identifier-set'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_datatable_putdatatable: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+(?:,\\d)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'id',
          true,
        ],
        ['text', '/api/generic/data-table'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_datatable_putdatatable_1: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+(?:,\\d)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'id',
          true,
        ],
        ['text', '/api/generic/comment-table'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_devicebooking_getdevicebooking: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/device-booking'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_devicebooking_getdevicebookings: {
      tokens: [['text', '/api/generic/device-booking']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_devicebooking_postdevicebooking: {
      tokens: [['text', '/api/generic/device-booking']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_devicebooking_putdevicebooking: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/device-booking'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_device_getdevice: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/device'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_device_getdevices: {
      tokens: [['text', '/api/generic/device']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_device_postdevice: {
      tokens: [['text', '/api/generic/device']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_device_putdevice: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/device'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_device_transferownership: {
      tokens: [['text', '/api/generic/device/transfer-ownership']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_device_addtags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        ['variable', '/', '[^/]++', 'deviceIds', true],
        ['text', '/api/generic/device'],
      ],
      defaults: [],
      requirements: {
        edeviceIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_device_removetags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'deviceIds',
          true,
        ],
        ['text', '/api/generic/device'],
      ],
      defaults: [],
      requirements: {
        deviceIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_devicedata_getdevicedata: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/device-data'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_devicedata_getdevicedatas: {
      tokens: [['text', '/api/generic/device-data']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_devicedata_postdevicedata: {
      tokens: [['text', '/api/generic/device-data']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_devicedata_putdevicedata: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/device-data'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entityexport_getentityexports: {
      tokens: [['text', '/api/generic/entity-export']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_entityexport_postentityexport: {
      tokens: [['text', '/api/generic/entity-export']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entityimport_getentityimports: {
      tokens: [['text', '/api/generic/entity-import']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_entityimport_postentityimport: {
      tokens: [['text', '/api/generic/entity-import']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entityimport_putentityimport: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/entity-import'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entityrelation_getentityrelations: {
      tokens: [['text', '/api/generic/entity-relation']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_entityrelation_postentityrelation: {
      tokens: [['text', '/api/generic/entity-relation']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entityrelation_putentityrelation: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/entity-relation'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entitystate_getentitystates: {
      tokens: [['text', '/api/generic/entity-state']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_entitystate_postentitystate: {
      tokens: [['text', '/api/generic/entity-state']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entitystate_putentitystate: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/entity-state'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entitystateworkflow_getentitystateworkflows: {
      tokens: [['text', '/api/generic/entity-state-workflow']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_entitystateworkflow_postentitystateworkflow: {
      tokens: [['text', '/api/generic/entity-state-workflow']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entitystateworkflow_putentitystateworkflow: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/entity-state-workflow'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entitystateworkflow_transferownership: {
      tokens: [
        [
          'text',
          '/api/generic/entity-state-workflow/transfer-ownership',
        ],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entityuser_getentityusers: {
      tokens: [['text', '/api/generic/entity-user']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_entityuser_postentityuser: {
      tokens: [['text', '/api/generic/entity-user']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entityuser_putentityuser: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/entity-user'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entityuserrole_getentityuserroles: {
      tokens: [['text', '/api/generic/entity-user-role']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_entityuserrole_postentityuserrole: {
      tokens: [['text', '/api/generic/entity-user-role']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entityuserrole_putentityuserrole: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/entity-user-role'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entityuserrolerequirement_postentityuserrolerequirement: {
      tokens: [['text', '/api/generic/entity-user-role-requirement']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entityuserrolerequirement_putentityuserrolerequirement: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/entity-user-role-requirement'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entityview_getentityviews: {
      tokens: [['text', '/api/generic/entity-view']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_entityview_postentityview: {
      tokens: [['text', '/api/generic/entity-view']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entityview_putentityview: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/entity-view'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entityview_postfilterentityviews: {
      tokens: [['text', '/api/generic/entity-view/filter']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_entityview_addtags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'entityViewIds',
          true,
        ],
        ['text', '/api/generic/entity-view'],
      ],
      defaults: [],
      requirements: {
        entityViewIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entityview_removetags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'entityViewIds',
          true,
        ],
        ['text', '/api/generic/entity-view'],
      ],
      defaults: [],
      requirements: {
        entityViewIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_entityview_addfolders: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'folderIds',
          true,
        ],
        ['text', '/folder'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'entityViewIds',
          true,
        ],
        ['text', '/api/generic/entity-view'],
      ],
      defaults: [],
      requirements: {
        entityViewIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        folderIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_entityview_removefolders: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'folderIds',
          true,
        ],
        ['text', '/folder'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'entityViewIds',
          true,
        ],
        ['text', '/api/generic/entity-view'],
      ],
      defaults: [],
      requirements: {
        entityViewIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        folderIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_experiment_getexperiment: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/experiment'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_experiment_getexperiments: {
      tokens: [['text', '/api/generic/experiment']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_experiment_postexperiment: {
      tokens: [['text', '/api/generic/experiment']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_experiment_putexperiment: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/experiment'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_experiment_putbatchexperiment: {
      tokens: [['text', '/api/generic/experiment/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_experimentworkflow_getexperimentworkflow: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/experiment-workflow'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_experimentworkflow_getexperimentworkflows: {
      tokens: [['text', '/api/generic/experiment-workflow']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_experimentworkflow_postexperimentworkflow: {
      tokens: [['text', '/api/generic/experiment-workflow']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_experimentworkflow_putexperimentworkflow: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/experiment-workflow'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_experimentworkflow_addassignees: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'assigneeIds',
          true,
        ],
        ['text', '/assignee'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'experimentWorkflowIds',
          true,
        ],
        ['text', '/api/generic/experiment-workflow'],
      ],
      defaults: [],
      requirements: {
        experimentWorkflowIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        assigneeIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_experimentworkflow_removeassignees: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'assigneeIds',
          true,
        ],
        ['text', '/assignee'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'experimentWorkflowIds',
          true,
        ],
        ['text', '/api/generic/experiment-workflow'],
      ],
      defaults: [],
      requirements: {
        experimentWorkflowIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        assigneeIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_experimentworkflow_addtags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'experimentWorkflowIds',
          true,
        ],
        ['text', '/api/generic/experiment-workflow'],
      ],
      defaults: [],
      requirements: {
        experimentWorkflowIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_experimentworkflow_removetags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'experimentWorkflowIds',
          true,
        ],
        ['text', '/api/generic/experiment-workflow'],
      ],
      defaults: [],
      requirements: {
        experimentWorkflowIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_experimentworkflow_addfolders: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'folderIds',
          true,
        ],
        ['text', '/folder'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'experimentWorkflowIds',
          true,
        ],
        ['text', '/api/generic/experiment-workflow'],
      ],
      defaults: [],
      requirements: {
        experimentWorkflowIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        folderIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_experimentworkflow_removefolders: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'folderIds',
          true,
        ],
        ['text', '/folder'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'experimentWorkflowIds',
          true,
        ],
        ['text', '/api/generic/experiment-workflow'],
      ],
      defaults: [],
      requirements: {
        experimentWorkflowIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        folderIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_experimentworkflow_transferownership: {
      tokens: [
        [
          'text',
          '/api/generic/experiment-workflow/transfer-ownership',
        ],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_experimentworkflowlink_getexperimentworkflowlinks: {
      tokens: [['text', '/api/generic/experiment-workflow-link']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_experimentworkflowlink_postexperimentworkflowlink: {
      tokens: [['text', '/api/generic/experiment-workflow-link']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_experimentworkflowlink_putlegacyexperimentworkflowlink: {
      tokens: [['text', '/api/generic/experiment-workflow-link']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_experimentworkflowlink_putexperimentworkflowlink: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/experiment-workflow-link'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_fielddefinition_getfielddefinitions: {
      tokens: [['text', '/api/generic/field-definition']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_fielddefinition_postfielddefinition: {
      tokens: [['text', '/api/generic/field-definition']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_fielddefinition_putfielddefinition: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/field-definition'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_file_getfile: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/file'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_file_getfiles: {
      tokens: [['text', '/api/generic/file']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_file_postfile: {
      tokens: [['text', '/api/generic/file']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_file_putfile: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/file'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_file_deletefile: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'ids',
          true,
        ],
        ['text', '/api/generic/file'],
      ],
      defaults: [],
      requirements: {
        ids: '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_file_downloadfile: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+(?:,\\d)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'id',
          true,
        ],
        ['text', '/api/generic/file/download'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_file_converturl: {
      tokens: [['text', '/api/generic/file/convert-url']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_folder_getfolders: {
      tokens: [['text', '/api/generic/collection']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_folder_getfolders_1: {
      tokens: [['text', '/api/generic/folder']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_folder_postfolder: {
      tokens: [['text', '/api/generic/collection']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_folder_postfolder_1: {
      tokens: [['text', '/api/generic/folder']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_folder_postbatchfolder: {
      tokens: [['text', '/api/generic/collection/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_folder_postbatchfolder_1: {
      tokens: [['text', '/api/generic/folder/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_folder_putfolder: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/collection'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_folder_putfolder_1: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/folder'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_groupautosharing_getgroupautosharings: {
      tokens: [['text', '/api/generic/group-auto-sharing']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_groupautosharing_postgroupautosharing: {
      tokens: [['text', '/api/generic/group-auto-sharing']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_groupautosharing_putgroupautosharing: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/group-auto-sharing'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_group_getgroup: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+(?:,\\d)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'id',
          true,
        ],
        ['text', '/api/generic/group'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_group_getgroup_1: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+(?:,\\d)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'id',
          true,
        ],
        ['text', '/api/generic/workspace'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_group_getgroups: {
      tokens: [['text', '/api/generic/group']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_group_getgroups_1: {
      tokens: [['text', '/api/generic/workspace']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_group_postgroup: {
      tokens: [['text', '/api/generic/group']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_group_postgroup_1: {
      tokens: [['text', '/api/generic/workspace']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_group_putgroup: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/group'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_group_putgroup_1: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/workspace'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_group_putbatchgroup: {
      tokens: [['text', '/api/generic/group/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_invitation_getinvitation: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/invitation'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_invitation_getinvitations: {
      tokens: [['text', '/api/generic/invitation']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_invitation_postinvitation: {
      tokens: [['text', '/api/generic/invitation']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_invitation_putinvitation: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/invitation'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_jupyteraccesstoken_postjupyteraccesstoken: {
      tokens: [['text', '/api/generic/jupyter-access-token']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_jupyterauthorizationcode_postjupyterauthorizationcode: {
      tokens: [['text', '/api/generic/jupyter-authorization-code']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_jupyterinstance_getjupyterinstance: {
      tokens: [
        [
          'variable',
          '/',
          '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/jupyter-instance'],
      ],
      defaults: [],
      requirements: {
        id: '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_jupyterinstance_getjupyterinstances: {
      tokens: [['text', '/api/generic/jupyter-instance']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_jupyterinstance_postjupyterinstance: {
      tokens: [['text', '/api/generic/jupyter-instance']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_jupyterinstance_putjupyterinstance: {
      tokens: [
        ['variable', '/', '[^/]++', 'id', true],
        ['text', '/api/generic/jupyter-instance'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_jupyternotebook_getjupyternotebooks: {
      tokens: [['text', '/api/generic/jupyter-notebook']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_jupyternotebook_getjupyternotebook: {
      tokens: [
        [
          'variable',
          '/',
          '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/jupyter-notebook'],
      ],
      defaults: [],
      requirements: {
        id: '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_jupyternotebook_postjupyternotebook: {
      tokens: [['text', '/api/generic/jupyter-notebook']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_jupyternotebook_putjupyternotebook: {
      tokens: [
        ['variable', '/', '[^/]++', 'id', true],
        ['text', '/api/generic/jupyter-notebook'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_jupyternotebook_postbatchjupyternotebook: {
      tokens: [['text', '/api/generic/jupyter-notebook/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_log_getlogs: {
      tokens: [['text', '/api/generic/log']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_metadata_getmetadata: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/metadata'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_metadata_getmetadatas: {
      tokens: [['text', '/api/generic/metadata']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_metadata_postmetadata: {
      tokens: [['text', '/api/generic/metadata']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_metadata_postbatchmetadata: {
      tokens: [['text', '/api/generic/metadata/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_metadata_putmetadata: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/metadata'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_metadata_deletemetadata: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'ids',
          true,
        ],
        ['text', '/api/generic/metadata'],
      ],
      defaults: [],
      requirements: {
        ids: '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_metadata_addprotocolvalues: {
      tokens: [
        ['variable', '/', '[^/]++', 'protocolValueIds', true],
        ['text', '/protocol-value'],
        ['variable', '/', '[^/]++', 'metadataIds', true],
        ['text', '/api/generic/metadata'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_metadata_removeprotocolvalues: {
      tokens: [
        ['variable', '/', '[^/]++', 'protocolValueIds', true],
        ['text', '/protocol-value'],
        ['variable', '/', '[^/]++', 'metadataIds', true],
        ['text', '/api/generic/metadata'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_molecule_getmolecules: {
      tokens: [['text', '/api/generic/molecule']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_molecule_putmolecule: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/molecule'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_molecule_postmolecule: {
      tokens: [['text', '/api/generic/molecule']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_notificationalert_postnotificationalert: {
      tokens: [['text', '/api/generic/notification-alert']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_notificationalert_putnotificationalert: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/notification-alert'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_notification_getnotifications: {
      tokens: [['text', '/api/generic/notification']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_notification_putnotification: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/notification'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_notification_postnotificationsetallviewedat: {
      tokens: [
        ['text', '/api/generic/notification/set-all-viewed-at'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_orderrequest_getorderrequests: {
      tokens: [['text', '/api/generic/order-request']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_orderrequest_postorderrequest: {
      tokens: [['text', '/api/generic/order-request']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_orderrequest_postbatchorderrequest: {
      tokens: [['text', '/api/generic/order-request/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_orderrequest_putorderrequest: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/order-request'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_orderrequest_addtags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'orderRequestIds',
          true,
        ],
        ['text', '/api/generic/order-request'],
      ],
      defaults: [],
      requirements: {
        orderRequestIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_orderrequest_removetags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'orderRequestIds',
          true,
        ],
        ['text', '/api/generic/order-request'],
      ],
      defaults: [],
      requirements: {
        orderRequestIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_orderrequest_transferownership: {
      tokens: [
        ['text', '/api/generic/order-request/transfer-ownership'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_organization_getorganizations: {
      tokens: [['text', '/api/generic/organization']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_organization_postorganization: {
      tokens: [['text', '/api/generic/organization']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_organization_putorganization: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/organization'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_organizationinvoice_getorganizationinvoices: {
      tokens: [['text', '/api/generic/organization-invoice']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_organizationmetabase_getorganizationmetabase: {
      tokens: [['text', '/api/generic/organization-metabase']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_organizationplan_getorganizationplan: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+(?:,\\d)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'id',
          true,
        ],
        ['text', '/api/generic/organization-plan'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_organizationplanprice_getorganizationplanprices: {
      tokens: [['text', '/api/generic/organization-plan-price']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_organizationsaml_putorganizationsaml: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/organization-saml'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_organizationsaml_postorganizationsaml: {
      tokens: [['text', '/api/generic/organization-saml']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_permalink_getpermalink: {
      tokens: [
        ['variable', '/', '[a-zA-Z0-9_-]+', 'token', true],
        ['text', '/api/generic/perma-link'],
      ],
      defaults: [],
      requirements: { token: '[a-zA-Z0-9_-]+' },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_permission_getpermissions: {
      tokens: [['text', '/api/generic/permission']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_permission_postpermission: {
      tokens: [['text', '/api/generic/permission']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_permission_postbatchpermission: {
      tokens: [['text', '/api/generic/permission/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_permission_putpermission: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/permission'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_permission_deletepermission: {
      tokens: [
        ['variable', '/', '[^/]++', 'guids', true],
        ['text', '/api/generic/permission'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_permissionrole_getpermissionroles: {
      tokens: [['text', '/api/generic/permission-role']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_permissionrole_postpermissionrole: {
      tokens: [['text', '/api/generic/permission-role']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_permissionrole_putpermissionrole: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/permission-role'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_permissionrole_deletepermissionrole: {
      tokens: [
        ['variable', '/', '[^/]++', 'guids', true],
        ['text', '/api/generic/permission-role'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_permissionrolesetting_postpermissionrolesetting: {
      tokens: [['text', '/api/generic/permission-role-setting']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_profile_putprofile: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/profile'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_profile_setresearchareas: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'researchAreaIds',
          true,
        ],
        ['text', '/research-area'],
        ['variable', '/', '[^/]++', 'profileIds', true],
        ['text', '/api/generic/profile'],
      ],
      defaults: [],
      requirements: {
        profileId: '\\d+',
        researchAreaIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_profile_addresearchareas: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'researchAreaIds',
          true,
        ],
        ['text', '/research-area'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'profileIds',
          true,
        ],
        ['text', '/api/generic/profile'],
      ],
      defaults: [],
      requirements: {
        profileIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        researchAreaIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_profile_removeresearchareas: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'researchAreaIds',
          true,
        ],
        ['text', '/research-area'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'profileIds',
          true,
        ],
        ['text', '/api/generic/profile'],
      ],
      defaults: [],
      requirements: {
        profileIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        researchAreaIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_prosemirror_purifystate: {
      tokens: [['text', '/api/generic/prosemirror']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocolcollection_getprotocolcollection: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/protocol-collection'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocolcollection_getprotocolcollections: {
      tokens: [['text', '/api/generic/protocol-collection']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocolcollection_postprotocolcollection: {
      tokens: [['text', '/api/generic/protocol-collection']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocolcollection_putprotocolcollection: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/protocol-collection'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocolcollection_putbatchprotocolcollection: {
      tokens: [['text', '/api/generic/protocol-collection/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocolcollection_addtags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'protocolCollectionIds',
          true,
        ],
        ['text', '/api/generic/protocol-collection'],
      ],
      defaults: [],
      requirements: {
        protocolCollectionIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocolcollection_removetags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'protocolCollectionIds',
          true,
        ],
        ['text', '/api/generic/protocol-collection'],
      ],
      defaults: [],
      requirements: {
        protocolCollectionIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_protocolcollection_addfolders: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'folderIds',
          true,
        ],
        ['text', '/folder'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'protocolCollectionIds',
          true,
        ],
        ['text', '/api/generic/protocol-collection'],
      ],
      defaults: [],
      requirements: {
        protocolCollectionIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        folderIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocolcollection_removefolders: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'folderIds',
          true,
        ],
        ['text', '/folder'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'protocolCollectionIds',
          true,
        ],
        ['text', '/api/generic/protocol-collection'],
      ],
      defaults: [],
      requirements: {
        protocolCollectionIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        folderIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_protocolcollection_transferownership: {
      tokens: [
        [
          'text',
          '/api/generic/protocol-collection/transfer-ownership',
        ],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocolcondition_getprotocolconditions: {
      tokens: [['text', '/api/generic/protocol-condition']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocolcondition_postprotocolcondition: {
      tokens: [['text', '/api/generic/protocol-condition']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocolcondition_postfilterprotocolconditions: {
      tokens: [['text', '/api/generic/protocol-condition/filter']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocolcondition_putprotocolcondition: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/protocol-condition'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocolcondition_postbatchprotocolcondition: {
      tokens: [['text', '/api/generic/protocol-condition/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocol_getprotocol: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/protocol'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocol_getprotocols: {
      tokens: [['text', '/api/generic/protocol']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocol_postprotocol: {
      tokens: [['text', '/api/generic/protocol']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocol_putprotocol: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/protocol'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocoldevice_getprotocoldevices: {
      tokens: [['text', '/api/generic/protocol-device']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocoldevice_postprotocoldevice: {
      tokens: [['text', '/api/generic/protocol-device']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocoldevice_putprotocoldevice: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/protocol-device'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocolstep_getprotocolsteps: {
      tokens: [['text', '/api/generic/protocol-step']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocolstep_postprotocolstep: {
      tokens: [['text', '/api/generic/protocol-step']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocolstep_putprotocolstep: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/protocol-step'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocolstep_postbatchprotocolstep: {
      tokens: [['text', '/api/generic/protocol-step/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocoltable_getprotocoltables: {
      tokens: [['text', '/api/generic/protocol-table']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocoltable_postprotocoltable: {
      tokens: [['text', '/api/generic/protocol-table']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocoltable_postbatchprotocoltable: {
      tokens: [['text', '/api/generic/protocol-table/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocoltable_putprotocoltable: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/protocol-table'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocoltimer_getprotocoltimers: {
      tokens: [['text', '/api/generic/protocol-timer']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocoltimer_postprotocoltimer: {
      tokens: [['text', '/api/generic/protocol-timer']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocoltimer_putprotocoltimer: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/protocol-timer'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_protocolvalue_getprotocolvalues: {
      tokens: [['text', '/api/generic/protocol-value']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_protocolvalue_postprotocolvalue: {
      tokens: [['text', '/api/generic/protocol-value']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocolvalue_postbatchprotocolvalue: {
      tokens: [['text', '/api/generic/protocol-value/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_protocolvalue_putprotocolvalue: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/protocol-value'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_purchaseorder_getpurchaseorders: {
      tokens: [['text', '/api/generic/purchase-order']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_purchaseorder_postpurchaseorder: {
      tokens: [['text', '/api/generic/purchase-order']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_purchaseorder_putpurchaseorder: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/purchase-order'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_researcharea_getresearchareas: {
      tokens: [['text', '/api/generic/research-area']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_researcharea_postresearcharea: {
      tokens: [['text', '/api/generic/research-area']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resource_getresource: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/resource'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_resource_getresources: {
      tokens: [['text', '/api/generic/resource']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_resource_postresource: {
      tokens: [['text', '/api/generic/resource']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resource_postbatchresource: {
      tokens: [['text', '/api/generic/resource/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resource_putresource: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/resource'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_resource_putbatchresource: {
      tokens: [['text', '/api/generic/resource/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_resource_addtags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'resourceIds',
          true,
        ],
        ['text', '/api/generic/resource'],
      ],
      defaults: [],
      requirements: {
        resourceIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_resource_removetags: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'tagIds',
          true,
        ],
        ['text', '/tag'],
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'resourceIds',
          true,
        ],
        ['text', '/api/generic/resource'],
      ],
      defaults: [],
      requirements: {
        resourceIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
        tagIds:
          '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_resource_transferownership: {
      tokens: [['text', '/api/generic/resource/transfer-ownership']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resource_postfilterresources: {
      tokens: [['text', '/api/generic/resource/filter']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resource_postfiltermetadatas: {
      tokens: [['text', '/api/generic/resource/metadata/filter']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resource_postassigncustomidentifier: {
      tokens: [['text', '/api/generic/resource/custom-identifier']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resourceitem_getresourceitem: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'id',
          true,
        ],
        ['text', '/api/generic/resource-item'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_resourceitem_getresourceitems: {
      tokens: [['text', '/api/generic/resource-item']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_resourceitem_postresourceitem: {
      tokens: [['text', '/api/generic/resource-item']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resourceitem_postbatchresourceitem: {
      tokens: [['text', '/api/generic/resource-item/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resourceitem_putresourceitem: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/resource-item'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_resourceitem_postfilterresourceitems: {
      tokens: [['text', '/api/generic/resource-item/filter']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resourceitem_postfiltermetadatas: {
      tokens: [
        ['text', '/api/generic/resource-item/metadata/filter'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resourcelocation_getresourcelocation: {
      tokens: [
        [
          'variable',
          '/',
          '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
          'guid',
          true,
        ],
        ['text', '/api/generic/resource-location'],
      ],
      defaults: [],
      requirements: {
        guid: '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_resourcelocation_getresourcelocations: {
      tokens: [['text', '/api/generic/resource-location']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_resourcelocation_postresourcelocation: {
      tokens: [['text', '/api/generic/resource-location']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_resourcelocation_putresourcelocation: {
      tokens: [
        ['variable', '/', '[^/]++', 'guid', true],
        ['text', '/api/generic/resource-location'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_resourcelocation_postbatchresourcelocation: {
      tokens: [['text', '/api/generic/resource-location/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_richtext_putrichtext: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+(?:,\\d)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'id',
          true,
        ],
        ['text', '/api/generic/rich-text'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_richtext_putrichtext_1: {
      tokens: [
        [
          'variable',
          '/',
          '\\d+(?:,\\d)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'id',
          true,
        ],
        ['text', '/api/generic/comment-post'],
      ],
      defaults: [],
      requirements: {
        id: '\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_securitylog_getsecuritylogs: {
      tokens: [['text', '/api/generic/security-log']],
      defaults: [],
      requirements: {
        id: '\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_sequence_putsequence: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/sequence'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_sharelink_getsharelinks: {
      tokens: [['text', '/api/generic/share-link']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_sharelink_postsharelink: {
      tokens: [['text', '/api/generic/share-link']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_sharelink_putsharelink: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/share-link'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_sharelink_accept: {
      tokens: [
        ['variable', '/', '.+', 'token', true],
        ['text', '/api/generic/share-link/accept'],
      ],
      defaults: [],
      requirements: { token: '.+' },
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_sharelink_emailsharelink: {
      tokens: [['text', '/api/generic/share-link/email']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_sharelinkinvitation_getsharelinkinvitations: {
      tokens: [['text', '/api/generic/share-link-invitation']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_sharelinkinvitation_organization: {
      tokens: [
        ['text', '/api/generic/share-link-invitation/organization'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_signature_postsignature: {
      tokens: [['text', '/api/generic/signature']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_signature_putsignature: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/signature'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_signature_deletesignature: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'ids',
          true,
        ],
        ['text', '/api/generic/signature'],
      ],
      defaults: [],
      requirements: {
        ids: '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_signaturerequest_getsignaturerequests: {
      tokens: [['text', '/api/generic/signature-request']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_signaturerequest_postsignaturerequest: {
      tokens: [['text', '/api/generic/signature-request']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_signaturerequest_putsignaturerequest: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/signature-request'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_signaturerequest_postbatchsignaturerequest: {
      tokens: [['text', '/api/generic/signature-request/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_signaturerequirement_postsignaturerequirement: {
      tokens: [['text', '/api/generic/signature-requirement']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_signaturerequirement_putsignaturerequirement: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/signature-requirement'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_stripe_createcheckoutsession: {
      tokens: [['text', '/stripe/create-checkout-session']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_stripe_createbillingportalsession: {
      tokens: [['text', '/stripe/create-billing-portal-session']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_tag_gettags: {
      tokens: [['text', '/api/generic/tag']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_tag_posttag: {
      tokens: [['text', '/api/generic/tag']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_tag_puttag: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/tag'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_tag_deletetag: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'ids',
          true,
        ],
        ['text', '/api/generic/tag'],
      ],
      defaults: [],
      requirements: {
        ids: '(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,(\\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_api_token_impersonate: {
      tokens: [['text', '/api/generic/token/impersonate']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_user_getusers: {
      tokens: [['text', '/api/generic/user']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_user_getinfo: {
      tokens: [['text', '/api/generic/user/info']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_user_getuser: {
      tokens: [
        [
          'variable',
          '/',
          '\\S+[@%40]\\S+\\.\\S+|\\d+(?:,\\d)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
          'id',
          true,
        ],
        ['text', '/api/generic/user'],
      ],
      defaults: [],
      requirements: {
        id: '\\S+[@%40]\\S+\\.\\S+|\\d+(,\\d)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})+(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*',
      },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_user_putuser: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/user'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_user_changepassword: {
      tokens: [['text', '/api/generic/user/change-password']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_user_logout: {
      tokens: [['text', '/api/generic/user/logout']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_user_postbatchuser: {
      tokens: [['text', '/api/generic/user/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_usergroup_getusergroups: {
      tokens: [['text', '/api/generic/user-group']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_usergroup_postusergroup: {
      tokens: [['text', '/api/generic/user-group']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_usergroup_postbatchusergroup: {
      tokens: [['text', '/api/generic/user-group/batch']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_usergroup_putusergroup: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/user-group'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_usergroupnotification_getusergroupnotifications: {
      tokens: [['text', '/api/generic/user-group-notification']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_usergroupnotification_putusergroupnotification: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/user-group-notification'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_userorganization_getuserorganizations: {
      tokens: [['text', '/api/generic/user-organization']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_userorganization_putuserorganization: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/user-organization'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_usersharelink_getusersharelinks: {
      tokens: [['text', '/api/generic/user-share-link']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_api_usersharelink_putusersharelink: {
      tokens: [
        [
          'variable',
          '/',
          '(?:\\d+(?:,\\d+)*|(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
          'id',
          true,
        ],
        ['text', '/api/generic/user-share-link'],
      ],
      defaults: [],
      requirements: {
        id: '(?:\\d+(,\\d+)*|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))*)',
      },
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_api_usertwofactor_setup: {
      tokens: [['text', '/api/generic/user/two-factor/setup']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_usertwofactor_confirm: {
      tokens: [['text', '/api/generic/user/two-factor/confirm']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_api_usertwofactor_disable: {
      tokens: [['text', '/api/generic/user/two-factor/disable']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_health_gethealth: {
      tokens: [['text', '/health']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_info_getinfo: {
      tokens: [['text', '/']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_info_getinfo_1: {
      tokens: [['text', '/info']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_ping_ping: {
      tokens: [['text', '/ping']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_publicapi_file_signedurl: {
      tokens: [
        ['variable', '/', '.+', 'path', true],
        ['text', '/public-api/file/signed-url'],
      ],
      defaults: [],
      requirements: { path: '.+' },
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_publicapi_jupyteraccesstoken_getjupyteraccesstoken: {
      tokens: [
        ['variable', '/', '[^/]++', 'accessToken', true],
        ['text', '/public-api/jupyter-access-token'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_publicapi_jupyterauthorizationcode_getjupyterauthorizationcode:
      {
        tokens: [
          ['variable', '/', '[^/]++', 'authorizationCode', true],
          ['text', '/public-api/jupyter-authorization-code'],
        ],
        defaults: [],
        requirements: [],
        hosttokens: [],
        methods: ['GET'],
        schemes: [],
      },
    app_publicapi_jupyterauthorizationcode_revokejupyterauthorizationcode:
      {
        tokens: [
          ['variable', '/', '[^/]++', 'authorizationCode', true],
          ['text', '/public-api/jupyter-authorization-code/revoke'],
        ],
        defaults: [],
        requirements: [],
        hosttokens: [],
        methods: ['POST'],
        schemes: [],
      },
    app_publicapi_saml_token: {
      tokens: [
        ['text', '/token'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/public-api/saml'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_saml_metadata: {
      tokens: [
        ['text', '/metadata'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/public-api/saml'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_publicapi_saml_getlogout: {
      tokens: [
        ['text', '/logout'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/public-api/saml'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_publicapi_saml_postlogout: {
      tokens: [
        ['text', '/logout'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/public-api/saml'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_saml_invalidatesession: {
      tokens: [
        ['variable', '/', '[^/]++', 'samlSessionIndex', true],
        ['variable', '/', '[^/]++', 'samlNameId', true],
        ['text', '/invalidate-session'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/public-api/saml'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_publicapi_saml_acs: {
      tokens: [
        ['text', '/acs'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/public-api/saml'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_saml_redirect: {
      tokens: [
        ['text', '/redirect'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/public-api/saml'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_publicapi_saml_geturl: {
      tokens: [
        ['text', '/get-url'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/public-api/saml'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_publicapi_sharelink_getsharelinks: {
      tokens: [['text', '/public-api/share-link']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_publicapi_stripe_checkoutsessioncompleted: {
      tokens: [
        [
          'text',
          '/public-api/stripe/webhook/checkout-session-completed',
        ],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_postuser: {
      tokens: [['text', '/public-api/user']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_login: {
      tokens: [['text', '/public-api/user/login']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_logincheck: {
      tokens: [['text', '/public-api/user/login/check']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_logingoogle: {
      tokens: [['text', '/public-api/user/login/google']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_confirm: {
      tokens: [['text', '/public-api/user/confirm']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_confirm_1: {
      tokens: [['text', '/public-api/confirm']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_confirmresend: {
      tokens: [['text', '/public-api/user/confirm-resend']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_confirmresend_1: {
      tokens: [['text', '/public-api/generic/user/confirm-resend']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_resetpasswordsendemail: {
      tokens: [
        ['text', '/public-api/user/reset-password-send-email'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_resetpasswordsendemail_1: {
      tokens: [['text', '/public-api/reset-password-send-email']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_resetpassword: {
      tokens: [['text', '/public-api/user/reset-password']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_resetpassword_1: {
      tokens: [['text', '/public-api/reset-password']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_publicapi_user_tokenrefresh: {
      tokens: [['text', '/public-api/user/token/refresh']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_scim_group_getgroup: {
      tokens: [
        ['variable', '/', '[^/]++', 'id', true],
        ['text', '/Groups'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_scim_group_getgroups: {
      tokens: [
        ['text', '/Groups'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_scim_group_postgroup: {
      tokens: [
        ['text', '/Groups'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_scim_group_patchgroup: {
      tokens: [
        ['variable', '/', '[^/]++', 'id', true],
        ['text', '/Groups'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PATCH'],
      schemes: [],
    },
    app_scim_group_deletegroup: {
      tokens: [
        ['variable', '/', '[^/]++', 'id', true],
        ['text', '/Groups'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    app_scim_user_getuser: {
      tokens: [
        ['variable', '/', '[^/]++', 'id', true],
        ['text', '/Users'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_scim_user_getusers: {
      tokens: [
        ['text', '/Users'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['GET'],
      schemes: [],
    },
    app_scim_user_postuser: {
      tokens: [
        ['text', '/Users'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST'],
      schemes: [],
    },
    app_scim_user_putuser: {
      tokens: [
        ['variable', '/', '[^/]++', 'id', true],
        ['text', '/Users'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PUT'],
      schemes: [],
    },
    app_scim_user_patchuser: {
      tokens: [
        ['variable', '/', '[^/]++', 'id', true],
        ['text', '/Users'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['PATCH'],
      schemes: [],
    },
    app_scim_user_deleteuser: {
      tokens: [
        ['variable', '/', '[^/]++', 'id', true],
        ['text', '/Users'],
        ['variable', '/', '[^/]++', 'organization', true],
        ['text', '/scim'],
      ],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['DELETE'],
      schemes: [],
    },
    oneup_uploader: {
      tokens: [['text', '/api/generic/file/upload']],
      defaults: [],
      requirements: [],
      hosttokens: [],
      methods: ['POST', 'PUT', 'PATCH'],
      schemes: [],
    },
  },
  prefix: '',
  host: 'localhost',
  port: '',
  scheme: 'http',
  locale: '',
};
