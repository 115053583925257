import classNames from 'classnames';
import FileImage from 'labstep-web/components/File/Image';
import React, { CSSProperties, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IWithResizeProps } from './types';

const MIN_WIDTH = 10;

const ImageResizer: React.FC<IWithResizeProps> = ({
  file,
  resizeImage,
  attrs,
  focus,
  editable,
}) => {
  const [width, setWidth] = useState<number | null>(
    attrs.width || null,
  );

  const handleSliderChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const newWidth = Math.min(
      100,
      Math.max(MIN_WIDTH + 1, Number(e.target.value)),
    );
    setWidth(newWidth);
  };

  const handleResizeEnd = (e) => {
    e.stopPropagation();
    resizeImage({ width });
  };

  const [paragraphWidth, setParagraphWidth] = useState(100);
  useEffect(() => {
    const element = (
      document.getElementById(`file-resize-${file.id}`) as HTMLElement
    )?.parentElement?.closest('p') as HTMLElement;

    if (!element) {
      return;
    }
    setParagraphWidth(
      Math.floor(element.getBoundingClientRect().width),
    );
  }, [editable]);

  const widthStyle =
    width === null ? 'auto' : `${(width * paragraphWidth) / 100}px`;

  const imageContainerStyle: CSSProperties = {
    width: widthStyle,
    height: 'auto',
    overflow: 'hidden',
    maxWidth: '100%',
  };

  return (
    <div
      draggable={false}
      id={`file-resize-${file.id}`}
      className={classNames(styles.imageResizeContainer, {
        [styles.heightRestrict]: width === null,
      })}
      onDrag={(e) => e.preventDefault()}
    >
      <div style={imageContainerStyle} draggable={false}>
        <FileImage file={file} expandable={false} />
      </div>
      {editable && (
        <input
          className={styles.vSlider}
          draggable={false}
          type="range"
          min={MIN_WIDTH}
          max={width ? 2 * width - MIN_WIDTH : 100}
          value={width as number}
          onChange={handleSliderChange}
          onMouseUp={handleResizeEnd}
          onDragStart={(e) => e.preventDefault()}
        />
      )}
      {editable && (
        <input
          className={styles.hSlider}
          draggable={false}
          type="range"
          min={MIN_WIDTH}
          max={width ? 2 * width - MIN_WIDTH : 100}
          value={width as number}
          onChange={handleSliderChange}
          onMouseUp={handleResizeEnd}
          onDragStart={(e) => e.preventDefault()}
        />
      )}
    </div>
  );
};

export default ImageResizer;
