/**
 * Labstep
 *
 * @module screens/Experiment/Show/Center
 * @desc Center of ExperimentShow Screen
 */

import CommentShow from 'labstep-web/components/Comment/Show';
import RightPane from 'labstep-web/components/Layout/RightPane';
import MetadataManagerList from 'labstep-web/components/Metadata/Manager/List';
import MetadataShow, {
  MetadataShowName,
} from 'labstep-web/components/Metadata/Show';
import MetadataShowThread, {
  MetadataShowThreadName,
} from 'labstep-web/components/Metadata/Show/Thread';
import ProtocolDeviceManager from 'labstep-web/components/ProtocolDevice/Manager';
import ProtocolStepManager from 'labstep-web/components/ProtocolStep/Manager';
import ProtocolStepShowThread, {
  ProtocolStepShowThreadName,
} from 'labstep-web/components/ProtocolStep/Show/Thread';
import ProtocolTimerManager from 'labstep-web/components/ProtocolTimer/Manager';
import ProtocolValueManager from 'labstep-web/components/ProtocolValue/Manager';
import ProtocolValueShow, {
  ProtocolValueShowName,
} from 'labstep-web/components/ProtocolValue/Show';
import { ICONS } from 'labstep-web/constants/icons';
import { Experiment } from 'labstep-web/models/experiment.model';
import { DEFAULT_BACK_ROUTE } from 'labstep-web/screens/Experiment/Show/TopBar';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { IExperimentRightProps } from './types';

export const ExperimentRight: React.FC<IExperimentRightProps> = ({
  experiment,
  activeExperimentWorkflowRoute,
}: IExperimentRightProps) => (
  <RightPane
    route={{
      to: 'experiment_show',
      params: { id: experiment.id },
    }}
    // Show index when navigating back to Experiment Workflow
    paths={[
      activeExperimentWorkflowRoute?.path ||
        navigation.get(DEFAULT_BACK_ROUTE),
    ]}
    subPages={[
      {
        name: 'Steps',
        icon: ICONS.protocol.step,
        count: experiment.protocol_step_count,
        route: {
          to: 'experiment_show_steps',
          params: { id: experiment.id },
        },
        component: <ProtocolStepManager protocol={experiment} />,
      },
      {
        name: 'Timers',
        icon: ICONS.protocol.timer,
        count: experiment.protocol_timer_count,
        route: {
          to: 'experiment_show_timers',
          params: { id: experiment.id },
        },
        component: <ProtocolTimerManager protocol={experiment} />,
      },
      {
        name: 'Inventory',
        icon: ICONS.resource.primary,
        count: experiment.protocol_value_count,
        route: {
          to: 'experiment_show_values',
          params: { id: experiment.id },
        },
        component: <ProtocolValueManager protocol={experiment} />,
      },
      {
        name: 'Data',
        icon: ICONS.metadata.primary,
        count: experiment.metadata_count,
        route: {
          to: 'experiment_show_metadata',
          params: { id: experiment.id },
        },
        component: (
          <MetadataManagerList
            entity={experiment}
            isTemplate={experiment.isTemplate}
          />
        ),
      },
      {
        name: 'Devices',
        icon: ICONS.device.primary,
        count: experiment.protocol_device_count,
        route: {
          to: 'experiment_show_devices',
          params: { id: experiment.id },
        },
        component: <ProtocolDeviceManager parent={experiment} />,
      },
    ]}
    subPagesNested={[
      {
        icon: ICONS.resource.primary,
        route: {
          to: 'experiment_show_values_outputs',
          params: { id: experiment.id },
        },
        backRoute: {
          to: 'experiment_show',
          params: { id: experiment.id },
        },
        component: () => (
          <ProtocolValueManager protocol={experiment} />
        ),
        name: () => 'Inventory',
      },
      {
        icon: ICONS.protocol_value.primary,
        route: {
          to: 'experiment_show_value_show',
        },
        backRoute: {
          to: 'experiment_show_values',
          params: {
            id: experiment.id,
          },
        },
        component: ({ match }) => (
          <ProtocolValueShow id={match.params.childId} />
        ),
        name: ({ match }) => (
          <ProtocolValueShowName id={match.params.childId} />
        ),
      },
      {
        icon: ICONS.protocol_value.primary,
        route: {
          to: 'experiment_show_value_show_output',
        },
        backRoute: {
          to: 'experiment_show_values_outputs',
          params: {
            id: experiment.id,
          },
        },
        component: ({ match }) => (
          <ProtocolValueShow id={match.params.childId} />
        ),
        name: ({ match }) => (
          <ProtocolValueShowName id={match.params.childId} />
        ),
      },
      {
        icon: ICONS.metadata.primary,
        route: {
          to: 'experiment_show_metadata_show',
        },
        backRoute: {
          to: 'experiment_show_metadata',
          params: {
            id: experiment.id,
          },
        },
        component: ({ match }) => (
          <MetadataShow
            id={match.params.childId}
            parentEntityName={Experiment.entityName}
          />
        ),
        name: ({ match }) => (
          <MetadataShowName id={match.params.childId} />
        ),
      },
      {
        icon: ICONS.thread.primary,
        route: {
          to: 'experiment_show_thread_show',
        },
        backRoute: {
          to: 'experiment_show',
          params: {
            id: experiment.id,
          },
        },
        component: ({ match }) => (
          <CommentShow id={match.params.childId} />
        ),
        name: () => 'Thread',
      },
      {
        icon: ICONS.thread.primary,
        route: {
          to: 'experiment_show_metadata_show_thread',
        },
        backRoute: {
          to: 'experiment_show_metadata_show',
          params: {
            id: experiment.id,
          },
        },
        component: ({ match }) => (
          <MetadataShowThread id={match.params.childId} />
        ),
        name: ({ match }) => (
          <MetadataShowThreadName id={match.params.childId} />
        ),
      },
      {
        icon: ICONS.thread.primary,
        route: {
          to: 'experiment_show_step_show_thread',
        },
        backRoute: ({ match }) => ({
          to: 'experiment_show',
          params: { id: experiment.id },
          query: { step_id: match.params.childId },
        }),
        component: ({ match }) => (
          <ProtocolStepShowThread id={match.params.childId} />
        ),
        name: ({ match }) => (
          <ProtocolStepShowThreadName
            id={match.params.childId}
            parent={experiment}
          />
        ),
      },
      {
        icon: ICONS.thread.primary,
        route: {
          to: 'experiment_show_step_show_thread_show',
        },
        backRoute: {
          to: 'experiment_show_step_show_thread',
          params: { id: experiment.id },
        },
        component: ({ match }) => (
          <CommentShow id={match.params.threadId} />
        ),
        name: () => 'Thread',
      },
      {
        icon: ICONS.thread.primary,
        route: {
          to: 'experiment_show_metadata_show_thread_show',
        },
        backRoute: {
          to: 'experiment_show_metadata_show_thread',
          params: { id: experiment.id },
        },
        component: ({ match }) => (
          <CommentShow id={match.params.threadId} />
        ),
        name: () => 'Thread',
      },
    ]}
  />
);

export default ExperimentRight;
