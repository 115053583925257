/**
 * Labstep
 *
 * @module state/middleware/timeout-logout
 * @desc Fires logout action if timeout time has expired
 * This needs to be a middleware rather than an epic because epics
 * always run through your reducers before your Epics even receive them.
 */

import { ORGANIZATION_IDENTIFIERS } from 'labstep-web/constants/organization-identifiers';
import { selectAuthenticatedUserEntity } from 'labstep-web/state/selectors/authenticated-user';

const TIMEOUTS: Record<string, number> = {
  [ORGANIZATION_IDENTIFIERS.ochrebio]: 8 * 3600 * 1000,
  [ORGANIZATION_IDENTIFIERS.djsantibodies]: 4 * 3600 * 1000,
};

export const logoutAction = {
  type: 'START_LOGOUT',
  meta: {
    reason: 'TIMEOUT_LOGOUT',
  },
};

export const timeoutLogout =
  (store: any) => (next: any) => (action: any) => {
    const state = store.getState();
    if (
      !(
        action.type.includes('REQUEST') ||
        action.type.includes('PREPARE')
      ) ||
      action.type === 'START_LOGOUT'
    ) {
      return next(action);
    }
    const authenticatedUser = (state as any).authenticatedUser.byId;
    if (!authenticatedUser?.id) {
      return next(action);
    }
    const authenticatedUserEntity =
      selectAuthenticatedUserEntity(state);
    const identifier =
      authenticatedUserEntity.userOrganization?.organization
        .identifier;
    if (!identifier) {
      return next(action);
    }
    const timeout = TIMEOUTS[identifier];

    if (!timeout) {
      return next(action);
    }
    const lastActionTimestamp = (state as any).last_action_timestamp;

    if (lastActionTimestamp === null) {
      return next(action);
    }
    if (Date.now() - lastActionTimestamp > timeout) {
      return next(logoutAction);
    }
    return next(action);
  };
