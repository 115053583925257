/**
 * Labstep
 */

import { ERRORS } from 'labstep-web/constants/error';
import * as toasts from './toasts';
import { IToastProps } from './types';

const isSuccessDeleteAction = (toast) =>
  !toast.action_type.includes('RAW_OUTPUT') &&
  toast.action_type.startsWith('SUCCESS_UPDATE') &&
  toast.meta.body.deleted_at;

const isSuccessRestoreAction = (toast) =>
  !toast.action_type.includes('RAW_OUTPUT') &&
  toast.action_type.startsWith('SUCCESS_UPDATE') &&
  toast.meta.body.deleted_at === null;

const isSuccessRemoveAction = (toast) =>
  !toast.action_type.includes('RAW_OUTPUT') &&
  toast.action_type.startsWith('SUCCESS_REMOVE');

export const getToastParams = (
  toast: IToastProps['toast'],
  context,
) => {
  if (isSuccessRestoreAction(toast)) {
    return toasts.restoreToast(toast);
  }

  if (isSuccessRemoveAction(toast)) {
    if (toast.meta.entityName === 'tag') {
      return null;
    }
    if (toast.meta.parentName === 'experiment_workflow') {
      // Disabling for assignees
      return null;
    }
    return toasts.removeToast(toast);
  }

  if (
    toast.action_type === 'FAIL_CREATE_PERMISSION' &&
    toast.error &&
    toast.error.data &&
    toast.error.data.message &&
    toast.error.data.message.length === 1 &&
    toast.error.data.message[0].property === 'group' &&
    toast.error.data.message[0].message ===
      'This value is already used.'
  ) {
    return toasts.permissionDuplicateErrorToast();
  }

  if (
    toast.meta &&
    toast.meta.bulkShare &&
    toast.action_type === 'SUCCESS_CREATE_PERMISSION'
  ) {
    return toasts.sharedToast;
  }

  if (
    toast.meta &&
    toast.meta.denormalized_payload &&
    toast.meta.denormalized_payload.owner &&
    toast.meta.denormalized_payload.owner.name &&
    toast.action_type === 'SUCCESS_TRANSFER_OWNERSHIP'
  ) {
    return toasts.transferOwnershipToast(toast);
  }

  if (
    toast.action_type.startsWith('SUCCESS_ADD_TAG_TO') &&
    !toast.action_type.includes('RAW_OUTPUT')
  ) {
    return toasts.addTagToast(toast);
  }

  if (
    toast.action_type === 'SUCCESS_UPDATE_USER' &&
    toast.meta.body.consented_at
  ) {
    return toasts.marketingConsentToast;
  }

  if (toast.action_type === 'SUCCESS_CREATE_SIGNATURE_REQUEST') {
    return toasts.createSignatureRequestToast;
  }

  if (toast.action_type === 'SUCCESS_CREATE_ORDER_REQUEST') {
    return toasts.createOrderRequestToast;
  }

  if (
    toast.action_type.startsWith('SUCCESS_ADD_FOLDER') &&
    !toast.action_type.includes('RAW_OUTPUT')
  ) {
    return toasts.addFolderToast;
  }

  if (
    toast.action_type === 'SUCCESS_UPDATE_USER_GROUP_NOTIFICATION'
  ) {
    return toasts.disableNotificationsToast;
  }

  if (toast.action_type === 'SUCCESS_CREATE_ENTITY_EXPORT') {
    return toasts.createExportToast;
  }

  if (
    toast.action_type === 'SUCCESS_SHARELINK_INVITATION_ORGANIZATION'
  ) {
    return toasts.sendInvitationsToast;
  }

  if (toast.action_type === 'SUCCESS_CREATE_PROTOCOL') {
    if (toast.meta.body.experiment_id) {
      if (toast.meta.addToProtocolLibrary) {
        return toasts.addToProtocolLibraryToast;
      }
      if (toast.meta.body.protocol_collection_id) {
        return toasts.createNewProtocolFromExperimentWithCollectionToast(
          toast,
        );
      }
      return toasts.createProtocolFromExperimentToast(toast);
    }

    return toasts.createProtocolToast;
  }

  if (
    toast.action_type === 'SUCCESS_CREATE_EXPERIMENT' &&
    toast.meta.body.ended_at
  ) {
    return toasts.experimentCompletedToast(toast);
  }

  // Showing when creating a single protocol collection in experiment-workflow/workflow
  if (
    toast.action_type === 'SUCCESS_CREATE_PROTOCOL_COLLECTION' &&
    toast.meta.normalize === 'protocol_collection'
  ) {
    return toasts.createProtocolCollectionToast(toast, context);
  }

  if (toast.action_type === 'SUCCESS_CREATE_RESOURCE') {
    // Adding to resource_list
    if (
      toast.meta.denormalized_payload.parent &&
      toast.meta.denormalized_payload.parent.id
    ) {
      return toasts.addResourceToResourceListToast(toast);
    }

    // FIXME toast.meta.denormalized_payload.is_template
    return toasts.createResourceToast(toast);
  }

  // Metadata duplicate label
  if (
    toast.action_type === 'FAIL_CREATE_METADATA' ||
    toast.action_type === 'FAIL_UPDATE_METADATA'
  ) {
    if (
      toast.error.data &&
      toast.error.data.message[0].message ===
        'There is already a metadata with this label.'
    ) {
      return toasts.metadataLabelExistsToast;
    }
  }

  if (toast.action_type === 'SUCCESS_ASK_EXPERT') {
    return toasts.askExpertToast;
  }

  if (toast.action_type === 'SUCCESS_EMAIL_SHARELINK') {
    return toasts.successEmailSharelinkToast;
  }

  if (toast.action_type === 'FAIL_EMAIL_SHARELINK') {
    return toasts.failEmailSharelinkToast;
  }

  if (toast.action_type === 'FAIL_SHARELINK_ACCEPT') {
    return toasts.failSharelinkAcceptToast(toast);
  }

  if (toast.action_type === 'FAIL_UPDATE_USER_GROUP') {
    return toasts.failUpdateUserGroupToast(toast);
  }

  if (toast.action_type === 'FAIL_CREATE_FILE') {
    return toasts.failCreateFileToast(toast);
  }

  if (
    toast.action_type === 'SUCCESS_UPDATE_ORDER_REQUEST' &&
    toast.meta.body.purchase_order_id
  ) {
    return toasts.addToOrderToast;
  }

  if (
    toast.action_type === 'FAIL_UPDATE_ORGANIZATION' &&
    toast.meta.body.whitelist_ip &&
    toast.error.status === 400
  ) {
    return toasts.whitelistIpErrorToast;
  }

  if (toast.action_type === 'FAIL_UPDATE_PROTOCOL') {
    if (
      toast.error &&
      toast.error.data &&
      toast.error.data.message.includes(
        'has been used in an experiment',
      )
    ) {
      return null;
    }
    return toasts.failUpdateProtocolToast;
  }

  if (
    toast.action_type === 'FAIL_CREATE_REVIEW' &&
    toast.error.status === 400
  ) {
    return toasts.failCreateReviewToast;
  }

  if (
    toast.action_type === 'FAIL_CREATE_TAG' &&
    toast.error.status === 400
  ) {
    return toasts.failCreateTagToast;
  }

  if (
    toast.action_type === 'FAIL_CREATE_RESOURCE_LOCATION' &&
    toast.error.status === 400
  ) {
    return toasts.failCreateResourceLocationToast(toast);
  }

  if (toast.action_type === 'FAIL_UPDATE_PROTOCOL_TABLE') {
    return toasts.failUpdateProtocolTableToast(toast);
  }

  if (
    toast.action_type === 'FAIL_CREATE_CUSTOM_IDENTIFIER_SET' &&
    toast.error.status === 400
  ) {
    return toasts.failCreateCustomIdentifierSetToast;
  }

  // Keep above generic

  if (
    toast.error?.data?.error_code ===
    ERRORS.ORGANIZATION_AT_LEAST_ONE_ADMIN
  ) {
    return toasts.organizationAtLeastOneAdmin;
  }

  if (
    toast.error?.data?.error_code ===
    ERRORS.GROUP_AUTO_SHARING_PROPAGATING
  ) {
    return toasts.groupAutoSharingPropagating;
  }

  if (
    toast.action_type === 'SUCCESS_CONFIRM_TOKEN' ||
    (toast.action_type === 'FAIL_CONFIRM_TOKEN' &&
      toast.error?.data?.error_code ===
        ERRORS.TOKEN_ALREADY_CONFIRMED)
  ) {
    return toasts.confirmTokenToast;
  }

  // Generic Fail Toast
  if (
    toast.action_type.startsWith('FAIL_') &&
    !toast.action_type.includes('_RAW_OUTPUT')
  ) {
    return toasts.failToast;
  }

  if (
    toast.action_type === 'SUCCESS_UPDATE_EXPERIMENT' &&
    toast.meta.body.ended_at
  ) {
    return toasts.endExperimentToast(toast);
  }

  if (toast.action_type === 'SUCCESS_EXPORT_DATA') {
    return toasts.exportDataToast(toast);
  }

  if (toast.action_type === 'CUSTOM_TOAST') {
    return toast.toast;
  }

  if (toast.error === 'FAIL_REFRESH_TOKEN_ERROR') {
    return toasts.failRefreshTokenErrorToast;
  }

  return null;
};
