/**
 * Labstep
 *
 * @module state/selectors/thumbnail
 * @desc Selectors for Thumbnail
 */

import { stringifyParamsInOrder } from 'labstep-web/state/utils';
import { hasKey } from './helpers';

/**
 * Select thumbnail url
 *
 * @function
 * @param  {object} state
 * @return {string} url
 */
export const selectThumbnailUrl = (
  state: any,
  fileGuid: string,
  params: any,
) => {
  const { thumbnails } = state.thumbnail;
  const key = `${fileGuid}-${stringifyParamsInOrder(params)}`;

  return hasKey(thumbnails, key) ? thumbnails[key].url : null;
};

/**
 * Select thumbnail status
 *
 * @function
 * @param  {object} state
 * @return {string} status
 */
export const selectThumbnailStatus = (
  state: any,
  fileGuid: string,
  params: any,
) => {
  const { thumbnails } = state.thumbnail;
  const key = `${fileGuid}-${stringifyParamsInOrder(params)}`;

  return hasKey(thumbnails, key) ? thumbnails[key].status : null;
};

/**
 * Select thumbnail retry
 *
 * @function
 * @param  {object} state
 * @return {string} status
 */
export const selectThumbnailRetry = (
  state: any,
  fileGuid: string,
  params: any,
) => {
  const { thumbnails } = state.thumbnail;
  const key = `${fileGuid}-${stringifyParamsInOrder(params)}`;

  return hasKey(thumbnails, key) ? thumbnails[key].retry : null;
};
