/**
 * Labstep
 *
 * @module components/Metadata/Form/Create
 * @desc Form to create metadata
 */

import { getMetadataFormFields } from 'labstep-web/components/Metadata/Form/fields';
import { preSubmit } from 'labstep-web/components/Metadata/Form/preSubmit';
import {
  addMoleculeToBody,
  getOptionByType,
} from 'labstep-web/components/Metadata/Form/utils';
import FormCreateContainer, {
  FormCreate,
} from 'labstep-web/core/Form/Create';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import React, { useState } from 'react';
import { MetadataFormCreateProps } from './types';

export const MetadataFormCreate: React.FC<
  MetadataFormCreateProps
> = ({
  entity,
  isTemplate,
  body = {},
  metadataType,
  onSubmit,
  defaultValues: defaultValuesProp,
  noValue,
  hideLinkedTypes,
  ...rest
}) => {
  const { activeGroup } = useActiveGroup();

  const [type, setType] = useState(
    getOptionByType(metadataType || MetadataType.default),
  );

  const [fields, setFields] = useState(
    getMetadataFormFields(
      type.value,
      isTemplate,
      undefined,
      noValue,
      hideLinkedTypes,
      // defaultValuesProp,
    ),
  );

  const [defaultValues, setDefaultValues] = useState({
    ...defaultValuesProp,
    type,
    label: '',
    current_entity: entity,
  });
  if (metadataType === MetadataType.molecule) {
    setDefaultValues({
      ...defaultValues,
      label: 'Structure',
    });
  }
  const props = {
    body: addMoleculeToBody(
      { ...body, group_id: activeGroup!.guid },
      type.value,
    ),
    autoFocus: true,
    entityName: Metadata.entityName,
    preSubmit,
    onFormChange: (values: any): void => {
      if (values.type !== type.value) {
        setType(getOptionByType(values.type));
      }

      const newFields = getMetadataFormFields(
        type.value,
        isTemplate,
        undefined,
        noValue,
        hideLinkedTypes,
        values,
      );

      if (JSON.stringify(defaultValues) !== JSON.stringify(values)) {
        setFields(newFields);
        setDefaultValues(values);
      }
    },
    defaultValues,
    fields: fields,
    ...rest,
  };

  return !entity ? (
    <FormCreate
      {...props}
      onSubmit={(values: any, reset): void => {
        onSubmit?.({ ...values, ...props.body }, reset);
      }}
    />
  ) : (
    // <EntityCreateContainer
    //   entityName={Metadata.entityName}
    //   parentId={(entity as MetadataParent).metadata_thread.idAttr}
    //   parentName={MetadataThread.entityName}
    //   batch
    // >
    //   {({ create, status }) => (
    //     <MetadataFormCreateBulk
    //       showToast={function ({ type, message, options }: { type: any; message: any; options: any; }): void {
    //         throw new Error('Function not implemented.');
    //       }}
    //       entity={entity}
    //       create={create}
    //       status={status}
    //       options={props.options}
    //       isTemplate={isTemplate}
    //       {...props} />
    //   )}
    // </EntityCreateContainer>
    <FormCreateContainer
      {...props}
      parentId={entity.metadata_thread.idAttr}
      parentName={entity.metadata_thread.entityName}
    />
  );
};

export default MetadataFormCreate;
