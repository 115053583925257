import { useHasAccess } from 'labstep-web/components/Entity/Can';
import CardSelect from 'labstep-web/core/Card/Select';
import Flex from 'labstep-web/core/Flex';
import Radio from 'labstep-web/core/Radio';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  RadioGroupProps,
  RadioItemProps,
  RadioItemWithAccessProps,
} from './types';

const RadioItemWithAccess: React.FC<RadioItemWithAccessProps> = ({
  item,
  selected,
  onChange,
  isCustom,
  disabled,
  isDisabled,
  disabledMessage,
  ...rest
}) => {
  const itemDisabled = !useHasAccess(
    disabled!.editProps!.entityName,
    disabled!.editProps!.entityId,
    disabled!.editProps!.action,
  );

  if (isCustom) {
    return (
      <Flex>
        <CardSelect
          isSelected={item.value === selected}
          setIsSelected={() => onChange(item.value)}
          content={item.component}
          noCheckbox
          label={item.label}
          isDisabled={itemDisabled}
          disabledMessage={disabledMessage}
        />
      </Flex>
    );
  }

  return (
    <Radio
      {...item}
      disabled={itemDisabled}
      checked={item.value === selected}
      {...rest}
      onChange={() => onChange(item.value)}
    />
  );
};

const RadioItem: React.FC<RadioItemProps> = ({
  item,
  selected,
  onChange,
  isCustom,
  disabledMessage,
  isDisabled,
  ...rest
}) => {
  if (isCustom) {
    return (
      <Flex>
        <CardSelect
          isSelected={item.value === selected}
          setIsSelected={() => onChange(item.value)}
          content={item.component}
          noCheckbox
          label={item.label}
          isDisabled={isDisabled}
          disabledMessage={disabledMessage}
        />
      </Flex>
    );
  }

  return (
    <Radio
      {...item}
      disabled={isDisabled}
      checked={item.value === selected}
      {...rest}
      onChange={() => onChange(item.value)}
    />
  );
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  elementProps,
  control,
  name,
  autoFocus,
  onKeyPress,
  ...rest
}) => {
  const [selected, setSelected] = useState(
    elementProps?.defaultValue,
  );
  const isDisabled = elementProps?.disabled?.isDisabled !== false;
  const disabledMessage = elementProps?.disabled?.message;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): React.ReactElement => {
        const { onChange } = field;

        useEffect(() => {
          if (elementProps?.defaultValue) {
            field.onChange(elementProps.defaultValue);
          }
        }, []);

        return (
          <Flex spacing="gap">
            {elementProps?.items.map((item) =>
              elementProps?.disabled?.editProps &&
              item.disableException !== true ? (
                <RadioItemWithAccess
                  key={item.value}
                  item={item}
                  selected={selected}
                  onChange={(value: any) => {
                    onChange(value as any);
                    setSelected(value);
                  }}
                  isCustom={elementProps?.isCustom}
                  isDisabled={isDisabled && item.disableException}
                  disabledMessage={disabledMessage}
                  {...rest}
                  disabled={elementProps!.disabled!}
                />
              ) : (
                <RadioItem
                  key={item.value}
                  item={item}
                  selected={selected}
                  onChange={(value: any) => {
                    onChange(value as any);
                    setSelected(value);
                  }}
                  isCustom={elementProps?.isCustom}
                  disabledMessage={disabledMessage}
                  isDisabled={
                    isDisabled && item.disableException !== true
                  }
                  {...rest}
                />
              ),
            )}
          </Flex>
        );
      }}
    />
  );
};

export default RadioGroup;
