/**
 * Labstep
 *
 * @module models/field-definition
 * @desc Typescript export class for FieldDefinition
 */

import { Entity } from 'labstep-web/models/entity.model';

export enum EntityRelationType {
  one_way = 'One way',
  symmetric = 'Symmetric',
  asymmetric = 'Asymmetric',
}

export class FieldDefinition extends Entity {
  static readonly entityName = 'field_definition';

  get entityName(): string {
    return FieldDefinition.entityName;
  }

  constructor(data: Partial<FieldDefinition> = {}) {
    super();
    Object.assign(this, data);
  }

  name!: string;

  description!: string;

  data_type!: string;

  parent_metadata_entity_class_allowed!: string;

  entity_relation_type!: string;

  entity_relation_target_entity_class!: string;

  entity_relation_target_entity_filter!: Record<string, unknown>;

  is_multiple_entity_relations_allowed!: boolean;

  entity_relation_inverse_field_definition!: string;
}
