/**
 * Labstep
 *
 * @module prosemirror/nodes/conditions
 * @desc Conditions node
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';

const key = 'conditions';

const NodeConditions: LabstepNode = {
  key,
  spec: {
    draggable: false,
    group: 'block',
    parseDOM: [
      {
        tag: 'div',
        getAttrs: (node): false | Record<string, unknown> => {
          if (node instanceof HTMLElement) {
            return node.getAttribute('type') === key ? {} : false;
          }
          return false;
        },
      },
    ],
    toDOM() {
      return ['div', { type: 'conditions' }];
    },
  },
};

export default NodeConditions;
