/**
 * Labstep
 *
 * @module components/ProtocolValue/Action/AmountUpdateStatus
 * @desc Shows if amount has been updated
 */

import Icon from 'labstep-web/core/Icon';
import {
  canUpdateAmount,
  checkUnitBase,
  isAmountUpdated,
} from 'labstep-web/services/amount-unit.service';
import React from 'react';
import { IProtocolValueActionAmountUpdateStatusProps } from './types';

const ProtocolValueActionAmountUpdateStatus: React.FC<
  IProtocolValueActionAmountUpdateStatusProps
> = ({ protocolValue, showNotSpecifiedIfApplicable }) => {
  if (protocolValue.is_output) {
    return null;
  }

  if (protocolValue.is_variable) {
    if (
      protocolValue.is_input &&
      !protocolValue.variable_template &&
      protocolValue.protocol_value_template_children.length > 0
    ) {
      const protocolValues =
        protocolValue.protocol_value_template_children.filter(
          (c) => !(c.resource_item === null && c.amount === null),
        );
      if (protocolValues.length === 0) {
        return null;
      } else if (protocolValues.some((c) => !isAmountUpdated(c))) {
        return (
          <Icon
            name="warning sign"
            color="yellow"
            popup={{ content: 'Amount not updated in inventory' }}
          />
        );
      } else {
        return (
          <Icon
            name="check circle"
            color="green"
            popup={{ content: 'Amount updated in inventory' }}
          />
        );
      }
    }
    return null;
  }

  if (!protocolValue.resource_item) {
    if (showNotSpecifiedIfApplicable) {
      return (
        <Icon
          name="warning sign"
          color="yellow"
          popup={{ content: 'Item not specified' }}
        />
      );
    }
    return null;
  }
  if (protocolValue.amount === null) {
    return (
      <Icon
        name="warning sign"
        color="yellow"
        popup={{ content: 'Amount not specified' }}
      />
    );
  }
  if (!protocolValue.unit) {
    return (
      <Icon
        name="warning sign"
        color="yellow"
        popup={{ content: 'Unit not specified' }}
      />
    );
  }
  if (protocolValue.resource_item) {
    if (protocolValue.resource_item.amount === null) {
      return (
        <Icon
          name="warning sign"
          color="yellow"
          popup={{ content: 'Amount not specified in inventory' }}
        />
      );
    }
    if (protocolValue.resource_item.unit === null) {
      return (
        <Icon
          name="warning sign"
          color="yellow"
          popup={{ content: 'Unit not specified in inventory' }}
        />
      );
    }
    if (
      !checkUnitBase(
        protocolValue.unit,
        protocolValue.resource_item.unit,
      )
    ) {
      return (
        <Icon
          name="warning sign"
          color="yellow"
          popup={{
            content:
              'Mismatching units between the item and the inventory field',
          }}
        />
      );
    }
  }

  if (isAmountUpdated(protocolValue)) {
    return (
      <Icon
        name="check circle"
        color="green"
        popup={{ content: 'Amount updated in inventory' }}
      />
    );
  }
  if (!canUpdateAmount(protocolValue)) {
    return (
      <Icon
        name="warning sign"
        color="yellow"
        popup={{ content: 'Insufficient Amount Remaining' }}
      />
    );
  }

  return (
    <Icon
      name="warning sign"
      color="yellow"
      popup={{ content: 'Amount not updated in inventory' }}
    />
  );
};

export default ProtocolValueActionAmountUpdateStatus;
