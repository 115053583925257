/**
 * Labstep
 *
 * @module state/epics/prosemirror
 */

import { ProseMirrorStatus } from 'labstep-web/containers/ProseMirror/types';
import { Action } from 'labstep-web/models/action.model';
import { readEntities } from 'labstep-web/state/actions/entity';
import { showToast } from 'labstep-web/state/actions/ui';
import {
  selectProseMirrorActiveEntity,
  selectProseMirrorStatus,
  setProseMirrorStatusConflictAction,
} from 'labstep-web/state/new/prosemirror';
import { LabstepReduxState } from 'labstep-web/state/types';
import { stringifyParamsInOrder } from 'labstep-web/state/utils';
import { isEqual } from 'lodash';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

/**
 * Do a GET request to reload state from database.
 */
export const requestFreshDataActionSuccessEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'WEBSOCKET_CONNECT' ||
        action.type === 'PROSEMIRROR_REQUEST_FRESH_DATA',
    ),
    map(() => {
      const activeEntity = selectProseMirrorActiveEntity(
        state$.value,
      );

      if (activeEntity) {
        const params = {
          id: activeEntity.entityId,
          get_single: 1,
        };
        return readEntities(activeEntity.entityName, params, {
          meta: {
            identifier: stringifyParamsInOrder(params),
            normalize: activeEntity.entityName,
            origin: 'websocket',
          },
        });
      }
      return {
        type: 'NO_ACTIVE_PROSEMIRROR',
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_PROSEMIRROR_REQUEST_FRESH_DATA',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Force refresh prosemirror after getting a successful GET call.
 */
export const forceRefreshActionSuccessEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_READ_EXPERIMENT_RAW_OUTPUT' ||
        action.type === 'SUCCESS_READ_PROTOCOL_RAW_OUTPUT' ||
        action.type === 'SUCCESS_UPDATE_EXPERIMENT_RAW_OUTPUT' ||
        action.type === 'SUCCESS_UPDATE_PROTOCOL_RAW_OUTPUT',
    ),
    map((action: Action) => {
      if (action.meta!.origin === 'websocket') {
        const status = selectProseMirrorStatus(state$.value);

        const areStatesEqual = isEqual(
          (action.payload as any).state,
          (state$.value as any).entities.experiment.byId[
            (action.payload as any).id
          ].state,
        );

        if (areStatesEqual) {
          return {
            type: 'EMPTY',
          };
        }
        if (
          status !== ProseMirrorStatus.debouncing &&
          status !== ProseMirrorStatus.error
        ) {
          return {
            type: 'PROSEMIRROR_FORCE_REFRESH',
          };
        }

        return setProseMirrorStatusConflictAction(
          (action.payload as any).update_state_log,
        );
      }

      return {
        type: 'SKIP_PROSEMIRROR_FORCE_REFRESH',
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_PROSEMIRROR_FORCE_REFRESH',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * On conflict, display toast.
 */
export const conflictToastActionSuccessEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'PROSEMIRROR_SET_STATUS' &&
        (action.meta as any) &&
        (action.meta as any).proseMirrorStatus ===
          ProseMirrorStatus.conflict,
    ),
    map((action: Action) =>
      showToast({
        type: 'success',
        message: `${
          (action.meta as any).conflict.author.first_name
        } ${
          (action.meta as any).conflict.author.last_name
        } changed the document.`,
        action_type: 'CUSTOM_TOAST',
        options: {
          header: 'Live update detected!',
          timeout: 10000,
        },
      }),
    ),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_PROSEMIRROR_CONFLICT_TOAST',
          payload: err,
        }),
        source$,
      ),
    ),
  );
