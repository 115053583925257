/**
 * Labstep
 */

import ProtocolConditionActionSetIsVariable from 'labstep-web/components/ProtocolCondition/Action/SetIsVariable';
import ProtocolValueAmountUpdateStatus from 'labstep-web/components/ProtocolValue/AmountUpdateStatus';
import ProtocolValueShowEditAmountUnit from 'labstep-web/components/ProtocolValue/Form/ShowEdit/AmountUnit';
import { ICONS } from 'labstep-web/constants/icons';
import Flex from 'labstep-web/core/Flex';
import TextWithHelp from 'labstep-web/core/Text/WithHelp';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import { RowsType } from '../types';

export const getAmountRows = (entity: ProtocolValue): RowsType => [
  {
    header: (
      <TextWithHelp
        text={entity.is_output ? 'Initial Amount' : 'Amount Used'}
        icon={
          entity.is_output
            ? ICONS.protocol_value.info.amount_created
            : ICONS.protocol_value.info.amount_used
        }
        header
      />
    ),
    content: (protocolValue): React.ReactElement => {
      const showEditAmount = (
        <ProtocolValueShowEditAmountUnit
          protocolValue={protocolValue}
        />
      );
      return protocolValue.is_experiment_child ? (
        <Flex style={{ alignItems: 'baseline' }}>
          <Flex grow={1}>{showEditAmount}</Flex>
          <ProtocolValueAmountUpdateStatus
            protocolValue={protocolValue}
            showNotSpecifiedIfApplicable={false}
          />
        </Flex>
      ) : (
        showEditAmount
      );
    },
    action: (protocolValue): React.ReactElement | null =>
      protocolValue.is_experiment_child ? (
        <ProtocolConditionActionSetIsVariable
          entity={protocolValue}
          protocol={protocolValue.parent}
        />
      ) : null,
    dataTestId: 'material-table-amount',
  },
];
