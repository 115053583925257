/**
 * Labstep
 *
 * @module core/RadioGroup/Radio
 * @desc Core checkbox component. Wrapper around semantic.
 */

import React from 'react';
import { Radio as SemanticRadio } from 'semantic-ui-react';
import { IRadioProps } from './types';

export const Radio: React.FC<IRadioProps> = ({
  onClick,
  disabled,
  ...rest
}) => (
  <SemanticRadio
    onClick={disabled ? undefined : onClick}
    disabled={disabled}
    {...rest}
  />
);

export default Radio;
