/**
 * Labstep
 *
 * @module components/Protocol/Form/ShowEdit/State/WarningModal
 * @desc Modal to block any additional action if protocol is locked
 */

import { ERRORS } from 'labstep-web/constants/error';
import Button from 'labstep-web/core/Button';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { IWarningModalProps } from './types';

export const ERROR_MESSAGES = {
  [ERRORS.PROSEMIRROR_STATE_IDS_DUPLICATE]:
    'Something went wrong and your changes could not be saved.',
  [ERRORS.PROSEMIRROR_STATE_IDS_MISMATCH]:
    'Something went wrong and your changes could not be saved.',
  [ERRORS.LOCK_PARENT_LOCKED]:
    'This document has been locked for editing.',
};

const WarningModal: React.FC<IWarningModalProps> = ({ status }) => {
  let message: string | null = null;
  if (
    (status?.error?.data as any)?.error_code &&
    ERROR_MESSAGES[(status?.error?.data as any).error_code]
  ) {
    message = ERROR_MESSAGES[(status?.error?.data as any).error_code];
  }
  if (message) {
    return (
      <ModalDefault
        header="Warning"
        closeIcon={null}
        open
        viewComponent={null}
        content={() => (
          <div>
            <p>{message}</p>
            <Button primary onClick={() => window.location.reload()}>
              Reload Page
            </Button>
          </div>
        )}
      />
    );
  }
  return null;
};

export default WarningModal;
