/**
 * Labstep
 *
 * @module models/entity-relation
 * @desc Typescript export class for EntityRelation
 */

import { Type } from 'class-transformer';
import { Device } from 'labstep-web/models/device.model';
import { Entity } from 'labstep-web/models/entity.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { User } from 'labstep-web/models/user.model';
import { EntityRelationParent } from '.';
import { FieldDefinition } from './field-definition.model';
import { ProtocolCollection } from './protocol-collection.model';

export class EntityRelation extends Entity {
  static readonly entityName = 'entity_relation';

  static readonly TYPE_ONE_WAY = 'one_way';

  static readonly TYPE_SYMMETRIC = 'symmetric';

  static readonly TYPE_ASYMMETRIC = 'asymmetric';

  get entityName(): string {
    return EntityRelation.entityName;
  }

  constructor(data: Partial<EntityRelation> = {}) {
    super();
    Object.assign(this, data);
  }

  @Type(() => FieldDefinition)
  public field_definition!: FieldDefinition;

  @Type(() => Device)
  public source_device!: Device;

  @Type(() => Device)
  public target_device!: Device;

  @Type(() => ProtocolCollection)
  public source_protocol_collection!: ProtocolCollection;

  @Type(() => ProtocolCollection)
  public target_protocol_collection!: ProtocolCollection;

  @Type(() => Resource)
  public source_resource!: Resource;

  @Type(() => Resource)
  public target_resource!: Resource;

  @Type(() => ResourceItem)
  public source_resource_item!: ResourceItem;

  @Type(() => ResourceItem)
  public target_resource_item!: ResourceItem;

  @Type(() => User)
  public source_user!: User;

  @Type(() => User)
  public target_user!: User;

  get source(): EntityRelationParent | null {
    if (this.source_device) {
      return this.source_device;
    }
    if (this.source_protocol_collection) {
      return this.source_protocol_collection;
    }
    if (this.source_resource) {
      return this.source_resource;
    }
    if (this.source_resource_item) {
      return this.source_resource_item;
    }
    if (this.source_user) {
      return this.source_user;
    }

    return null;
  }

  get target(): EntityRelationParent | null {
    if (this.target_device) {
      return this.target_device;
    }
    if (this.target_protocol_collection) {
      return this.target_protocol_collection;
    }
    if (this.target_resource) {
      return this.target_resource;
    }
    if (this.target_resource_item) {
      return this.target_resource_item;
    }
    if (this.target_user) {
      return this.target_user;
    }

    return null;
  }
}
