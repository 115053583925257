/**
 * Labstep
 *
 * @module state/reducers/entity/byId;
 * @desc Reducers for any entity
 */

import { Action } from 'labstep-web/models/action.model';
import { normalizeEntities } from 'labstep-web/services/normalize';
import { parentOf } from 'labstep-web/services/schema/helpers';
import { RESET_POSITION } from 'labstep-web/state/actions/position';
import { transferOwnershipActionType } from 'labstep-web/state/actions/transfer-ownership';
import { getOptimisticKey } from 'labstep-web/state/epics/optimistic';
import {
  addChildEntityToParent,
  addChildEntityToParentBasedOnBodyEntityUserRoleRequirement,
  addManyToMany,
  mergeByIds,
  removeChildEntityFromParents,
  removeManyToMany,
  setManyToMany,
} from 'labstep-web/state/reducers/helpers';
import { EntityNamesById } from 'labstep-web/state/types';
import camelCase from 'lodash/camelCase';
import {
  entityRelationsUpdate,
  previewArrayUpdate,
  updateEntityById,
} from './utils';

/**
 * Returns default reducer for entities organized by id.
 * Handles the following actions:
 * - Delete success
 * - Delete success - Remove child from parents
 * - Create success - Add child to parent
 * - Add/Remove/Set entities for ManyToMany relationships
 * - Timeline
 * - Pagination
 *
 * @function
 * @param  {object} state - Redux state
 * @param  {string} action - Redux action
 */
const byId =
  (entityName: EntityNamesById, entityNameUppercase: string) =>
  (state: any, action: Action): any => {
    if (action.type && action.type.startsWith('PREPARE_')) {
      return state;
    }

    if (
      action.type === RESET_POSITION &&
      action.entityName === entityName &&
      action.entities
    ) {
      const newState = { ...state };
      action.entities.forEach((entity) => {
        newState[entity.id] = {
          ...newState[entity.id],
          position: (entity as any).position,
        };
      });
      return newState;
    }

    // Updates per entity
    const updatedState = updateEntityById(state, action, entityName);
    if (updatedState) {
      return updatedState;
    }

    // Update entity for SUCCESS_TRANSFER_OWNERSHIP
    if (
      action.type ===
      `SUCCESS_${transferOwnershipActionType.toUpperCase}`
    ) {
      return mergeByIds(
        state,
        normalizeEntities(action.payload, action.meta.normalize)
          .entities[entityName],
        entityName,
      );
    }

    if (action.type && action.type.includes('_RAW_OUTPUT')) {
      return state;
    }

    if (
      action.meta &&
      action.type === `SUCCESS_DELETE_${entityNameUppercase}`
    ) {
      return state;
    }

    if (
      action.meta &&
      action.type === 'SUCCESS_CREATE_ENTITY_RELATION' &&
      entityName === 'resource'
    ) {
      return entityRelationsUpdate(state, action, entityName);
    }

    if (
      action.type === 'SUCCESS_CREATE_ENTITY_USER' &&
      entityName === action.meta.parentName
    ) {
      return previewArrayUpdate(state, action);
    }

    if (
      action.meta &&
      action.meta.parentName &&
      action.meta.parentName === entityName &&
      (action.type.includes('SUCCESS_CREATE') ||
        action.type === 'SUCCESS_CONVERT_URL')
    ) {
      return addChildEntityToParent(state, action, entityName);
    }

    if (
      action.meta &&
      action.meta.entityName !== entityName &&
      action.type.includes('SUCCESS_CREATE_SIGNATURE_REQUIREMENT') &&
      entityName === 'entity_user_role_requirement'
    ) {
      return addChildEntityToParentBasedOnBodyEntityUserRoleRequirement(
        state,
        action,
      );
    }

    if (
      action.meta &&
      action.meta.entityName &&
      action.type.includes('SUCCESS_DELETE') &&
      parentOf(action.meta.entityName).includes(camelCase(entityName))
    ) {
      return removeChildEntityFromParents(state, action, entityName);
    }

    if (
      action.type &&
      action.type.startsWith('SUCCESS_ADD') &&
      action.meta &&
      action.meta.parentName === entityName
    ) {
      const newState = addManyToMany(state, action, entityName);
      if (
        action.payload.entities &&
        action.payload.entities[entityName]
      ) {
        return mergeByIds(
          newState,
          action.payload.entities[entityName],
          entityName,
        );
      }
      return newState;
    }

    if (
      action.type &&
      action.type.startsWith('SUCCESS_REMOVE') &&
      action.meta &&
      action.meta.parentName === entityName
    ) {
      const newState = removeManyToMany(state, action, entityName);
      if (
        action.payload.entities &&
        action.payload.entities[entityName]
      ) {
        return mergeByIds(
          newState,
          action.payload.entities[entityName],
          entityName,
        );
      }
      return newState;
    }

    if (
      action.type &&
      action.type.startsWith('SUCCESS_SET') &&
      action.meta &&
      action.meta.parentName === entityName
    ) {
      return setManyToMany(state, action, entityName);
    }

    // Optimistic update - Request
    if (
      action.meta &&
      action.meta.optimistic &&
      action.type &&
      action.type.startsWith('REQUEST') &&
      !action.type.includes('REQUEST_RAW') &&
      entityName === action.meta.entityName
    ) {
      let body = { ...action.meta.body };
      if (body) {
        body = Object.keys(body).reduce((result, key: string) => {
          const optimisticKey = getOptimisticKey(key);

          return { ...result, [optimisticKey]: body[key] };
        }, {});
      }
      return {
        ...state,
        [action.meta.identifier]: {
          ...state[action.meta.identifier],
          ...body,
        },
      };
    }

    // Optimistic update - success
    if (
      action.meta &&
      action.meta.optimistic &&
      action.type &&
      action.type.includes('SUCCESS') &&
      !action.type.includes('SUCCESS_RAW') &&
      entityName === action.meta.entityName
    ) {
      return state;
    }

    // Optimistic update - Fail
    if (
      action.meta &&
      action.meta.optimistic &&
      action.type &&
      action.type.includes('FALLBACK_UPDATE') &&
      entityName === action.meta.entityName &&
      action.fallbackBody
    ) {
      if (
        Object.values(action.fallbackBody).some(
          (value) => value === undefined,
        )
      ) {
        return state;
      }
      return {
        ...state,
        [action.meta.identifier]: {
          ...state[action.meta.identifier],
          ...action.fallbackBody,
        },
      };
    }

    // Has Payload
    if (action.payload) {
      if (
        action.type &&
        action.type.includes('SUCCESS') &&
        (action.type.includes('_PAGE_') ||
          action.type.includes('_CURSOR_'))
      ) {
        return mergeByIds(
          state,
          action.payload.items.entities[entityName],
          entityName,
        );
      }
      if (
        action.payload.entities &&
        action.payload.entities[entityName]
      ) {
        return mergeByIds(
          state,
          action.payload.entities[entityName],
          entityName,
        );
      }
    }

    return state;
  };

export default byId;
