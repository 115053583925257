/**
 * Labstep
 *
 * @module prosemirror/nodes/reference
 * @desc Reference node
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import {
  ParseRule,
  Node as ProseMirrorNode,
} from 'prosemirror-model';

const key = 'reference';

const getAttrs: ParseRule['getAttrs'] = (node) => {
  if (node instanceof HTMLElement) {
    const id = node.getAttribute('data-reference-id');
    const parsedId = id && Number(id);
    const guid = node.getAttribute('data-reference-guid');
    const entityName = node.getAttribute('data-reference-entityName');

    if (!entityName && !(parsedId || guid)) {
      return false;
    }
    return {
      id: parsedId,
      guid,
      entityName,
    };
  }
  return false;
};

const NodeReference: LabstepNode = {
  key,
  spec: {
    attrs: {
      id: { default: null },
      entityName: { default: null },
      guid: { default: null },
    },
    inline: true,
    group: 'inline',
    marks: '',
    draggable: true,
    parseDOM: [{ tag: 'span', getAttrs }],
    toDOM(node: ProseMirrorNode) {
      const { id, guid, entityName } = node.attrs;
      return [
        'span',
        {
          [`data-${key}-id`]: id,
          [`data-${key}-guid`]: guid,
          [`data-${key}-entityName`]: entityName,
        },
      ];
    },
  },
};

export default NodeReference;
