/**
 * Labstep
 */

import { plainToInstance } from 'class-transformer';
import { Action } from 'labstep-web/models/action.model';
import { Comment as CommentModel } from 'labstep-web/models/comment.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Thread } from 'labstep-web/models/thread.model';
import { addCreatedEntityToCursorItemsArray } from 'labstep-web/state/reducers/helpers/add';
import clone from 'lodash/clone';
import uniq from 'lodash/uniq';

interface PinListParameters {
  is_pinned: boolean;
  parent_thread_id?: Thread['id'] | Thread['id'][];
  experiment_workflow_id?: ExperimentWorkflow['id'];
  sort: string;
  [key: string]: unknown;
}

export const getPinCommentListParametersParentThread = (
  threadIds: Thread['id'] | Thread['id'][],
): PinListParameters => ({
  is_pinned: true,
  parent_thread_id: threadIds,

  sort: '-pinned_at',
});

export const getPinCommentListParametersExperimentWorkflow = (
  experimentWorkflowId: ExperimentWorkflow['id'],
): PinListParameters => ({
  is_pinned: true,
  experiment_workflow_id: experimentWorkflowId,
  sort: '-pinned_at',
});

/**
 * Add/Remove a comment to a pin comment list.
 *
 * @function
 * @param  {object} state
 * @param  {object} action
 * @param  {string} key
 */
export const updatePinCommentList = (
  state: any,
  action: any,
  key: string,
): any => {
  if (state[key] && Array.isArray(state[key].items)) {
    if (action.meta.denormalized_payload.pinned_at) {
      return {
        ...state,
        [key]: {
          ...state[key],
          items: uniq([action.payload.result, ...state[key].items]),
        },
      };
    }
    return {
      ...state,
      [key]: {
        ...state[key],
        items: state[key].items.filter(
          (item: number) => item !== action.payload.result,
        ),
      },
    };
  }

  return state;
};

/**
 *
 * @description handles the case where the key has the overall parent key
 * (e.g. experiment_workflow_id) rather than the parent_thread_id
 * @param state Stae
 * @param action Action
 * @returns
 */
export const updateCommentLists = (state: any, action: Action) => {
  const { permission_entity_info } = action.meta.denormalized_payload;
  const updatedState = Object.keys(state).reduce((result, key) => {
    const parsedKey = JSON.parse(key);

    if (
      parsedKey[`${permission_entity_info.entityName}_id`] ===
        permission_entity_info.id &&
      !parsedKey.is_pinned
    ) {
      return {
        ...result,
        [key]: {
          ...state[key],
          items: [action.payload.result, ...state[key].items],
        },
      };
    }
    return { ...result, [key]: state[key] };
  }, {});
  return addCreatedEntityToCursorItemsArray(updatedState, action);
};

/**
 * Add/Remove a comment to all the pin comment lists with the same parent_thread_id.
 *
 * @function
 * @param  {object} state
 * @param  {object} action
 */
export const updatePinCommentLists = (
  state: any,
  action: any,
): any => {
  const updatedComment: any = plainToInstance(
    CommentModel,
    action.meta.denormalized_payload,
  );

  let newState = clone(state);

  Object.keys(state).forEach((key) => {
    const keyParameters = JSON.parse(key);
    const { permission_entity_info } = updatedComment;

    if (
      updatedComment.parent_thread &&
      keyParameters.is_pinned &&
      ((Array.isArray(keyParameters.parent_thread_id) &&
        keyParameters.parent_thread_id.includes(
          updatedComment.parent_thread.id,
        )) ||
        keyParameters[`${permission_entity_info?.entityName}_id`] ===
          permission_entity_info?.id)
    ) {
      newState = updatePinCommentList(newState, action, key);
    }
  });

  const parameters = getPinCommentListParametersParentThread(
    updatedComment.parentThreadIds,
  );
  newState = updatePinCommentList(
    newState,
    action,
    JSON.stringify(parameters),
  );

  return newState;
};
