/**
 * Labstep
 *
 * @module components/Entity/ListCreate/Search
 * @desc Search for an entity into a list create
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import SearchSelect from 'labstep-web/core/Select/Search';
import React from 'react';
import {
  EntityListCreateSearchContainerProps,
  EntityListCreateSearchProps,
} from './types';

export const EntityListCreateSearch: React.FC<
  EntityListCreateSearchProps
> = ({
  entityName,
  create,
  onChangeCreateProps,
  toggle,
  extraParams,
}) => {
  return (
    <SearchSelect
      labelKey="displayName"
      autoFocus
      openMenuOnFocus
      params={{
        ...extraParams,
      }}
      entityName={entityName}
      value={null}
      onBlur={toggle}
      onChange={(selectedOption) => {
        const createProps = onChangeCreateProps(selectedOption);
        create(createProps);
        toggle();
      }}
    />
  );
};

const EntityListCreateSearchContainer: React.FC<
  EntityListCreateSearchContainerProps
> = ({
  createEntityName,
  searchEntityName,
  onChangeCreateProps,
  ...rest
}) => (
  <EntityCreateContainer entityName={createEntityName}>
    {({ create }) => (
      <EntityListCreateSearch
        create={create}
        entityName={searchEntityName}
        onChangeCreateProps={onChangeCreateProps}
        {...rest}
      />
    )}
  </EntityCreateContainer>
);

export default EntityListCreateSearchContainer;
