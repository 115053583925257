/**
 * Labstep
 *
 * @module core/Action/Text
 * @desc Text component that prompts an action
 */

import classnames from 'classnames';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import styles from './styles.module.scss';
import { IActionTextProps } from './types';

const ActionText: React.FC<IActionTextProps> = ({
  onClick,
  disabled,
  placeholder,
  gridCell,
  underline = true,
  showEdit,
  popup,
  ...rest
}) => {
  const isUnderline = underline && !placeholder && !showEdit;
  const trigger = (
    <span
      data-cy="core/Action/Text"
      className={classnames({
        [styles.action]: !isUnderline && !gridCell,
        [styles.disabled]: disabled,
        [styles.underline]: isUnderline,
        [styles.placeholder]: placeholder,
        [styles.showEdit]: showEdit,
        [styles.gridCell]: gridCell,
      })}
      onClick={disabled ? undefined : onClick}
      {...rest}
    />
  );
  return popup && popup.content ? (
    <Popup size="mini" trigger={trigger} {...popup} />
  ) : (
    trigger
  );
};

export default ActionText;
