/**
 * Labstep
 *
 * @module services/table
 * @desc Helper methods for tables
 */

export const ROW_HEIGHT = 20;
export const COLUMN_WIDTH = 61;
export const ROW_LIMIT = 1000;
export const COLUMN_LIMIT = 1000;
export const N_COLUMNS = 4;
export const N_ROWS = 3;
export const MAX_HEIGHT = 400;
export const DEFAULT_ROW_COUNT = 1000;
export const DEFAULT_COLUMN_COUNT = 50;

export type TableData = {
  rows?: any[];
  columns?: any[];
  rowCount: number;
  columnCount: number;
  data: {
    dataTable: {
      [key: string]: {
        [key: string]: any;
      };
    };
  };
  name?: string;
  selections: any;
};

export type SpreadData = {
  sheets: TableData[];
};

export const calculateTableSize = (
  data: SpreadData,
  fullSize = false,
  baseHeight = ROW_HEIGHT + 28,
  baseWidth = 2 * COLUMN_WIDTH,
): { width: number; height: number } => {
  // Height
  let height = baseHeight;
  const sheet: TableData = Object.values(data.sheets)[0];
  if (sheet.rows) {
    height +=
      sheet.rows.reduce(
        (total, row) => total + (row ? row.size : ROW_HEIGHT),
        0,
      ) +
      (sheet.rowCount - sheet.rows.length) * ROW_HEIGHT;
  } else {
    height += sheet.rowCount * ROW_HEIGHT;
  }
  if (!fullSize && height > MAX_HEIGHT) {
    height = MAX_HEIGHT; // Cap height at max
  }
  const size: any = { height };
  // Width
  if (fullSize) {
    let width = baseWidth;
    if (sheet.columns) {
      width +=
        sheet.columns.reduce(
          (total, column) =>
            total + (column ? column.size : COLUMN_WIDTH),
          0,
        ) +
        (sheet.columnCount - sheet.columns.length) * COLUMN_WIDTH;
    } else {
      width += sheet.columnCount * COLUMN_WIDTH;
    }
    size.width = width;
  }
  return size;
};

/**
 * Gets size (rows, columns) given some values. If
 * values are over the limit it returns the upper limit.
 */
export const getFinalSize = (values: {
  rows?: number;
  columns?: number;
}) => {
  let { rows } = values;
  let { columns } = values;
  if (!rows) {
    rows = N_ROWS;
  } else if (rows > ROW_LIMIT) {
    rows = ROW_LIMIT;
  }

  if (!columns) {
    columns = N_COLUMNS;
  } else if (columns > COLUMN_LIMIT) {
    columns = COLUMN_LIMIT;
  }
  return { rows, columns };
};

export const generateTableData = (
  size = { rows: DEFAULT_ROW_COUNT, columns: DEFAULT_COLUMN_COUNT },
) => {
  const finalSize = getFinalSize(size);
  const { rows: rowCount, columns: columnCount } = finalSize;
  const defaultNode = {
    defaultDataNode: {
      style: {
        themeFont: 'Body',
      },
    },
  };
  const data = {
    name: 'Sheet1',
    rowCount,
    columnCount,
    data: {},
    colHeaderData: defaultNode,
    rowHeaderData: defaultNode,
    sheets: {
      Sheet1: {
        name: 'Sheet1',
        rowCount,
        columnCount,
        data: {},
        colHeaderData: defaultNode,
        rowHeaderData: defaultNode,
      },
    },
  };
  return data;
};

export const cleanTableData = (json: TableData) => {
  if (typeof json.columnCount === 'undefined') {
    json.columnCount = DEFAULT_COLUMN_COUNT;
  }
  if (json.data && json.data.dataTable) {
    const {
      data: { dataTable },
    } = json;
    const nRows = Object.keys(dataTable).length;
    if (json.rowCount < nRows) {
      json.rowCount = nRows;
    }
    const nColumns = Math.max(
      ...Object.values(dataTable).map((r) => Object.keys(r).length),
    );
    if (json.columnCount < nColumns) {
      json.columnCount = nColumns;
    }
  }

  delete json.selections; // FIXME: temp hack until validation is fixed
  return json;
};

export const cleanDesignerTableData = (json: SpreadData) => {
  Object.values(json.sheets).forEach((sheet: TableData) => {
    if (typeof sheet.columnCount === 'undefined') {
      sheet.columnCount = DEFAULT_COLUMN_COUNT;
    }

    if (typeof sheet.rowCount === 'undefined') {
      sheet.rowCount = DEFAULT_ROW_COUNT;
    }

    if (sheet.data && sheet.data.dataTable) {
      const {
        data: { dataTable },
      } = sheet;
      const nRows = Object.keys(dataTable).length;
      if (sheet.rowCount < nRows) {
        sheet.rowCount = nRows;
      }
      const nColumns = Math.max(
        ...Object.values(dataTable).map((r) => Object.keys(r).length),
      );
      if (sheet.columnCount < nColumns) {
        sheet.columnCount = nColumns;
      }
    }
    delete sheet.selections; // FIXME: temp hack until validation is fixed
  });

  return json;
};
