/**
 * Labstep
 *
 * @module components/Entity/ListToggle/Search
 * @desc Search for an entity to add into a list toggle
 */

import { EntityToggleContainer } from 'labstep-web/containers/Entity/Toggle';
import SearchSelect from 'labstep-web/core/Select/Search';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import React from 'react';
import {
  EntityListToggleSearchContainerProps,
  EntityListToggleSearchProps,
} from './types';

export const EntityListToggleSearch: React.FC<
  EntityListToggleSearchProps
> = ({
  parent,
  entityName,
  toggle,
  onChange,
  extraParams,
  withParentKey = true,
  ...rest
}) => {
  const params: Record<string, unknown> = {};
  if (withParentKey) {
    params[`${parent.entityName}_not_id`] = parent.id;
  }
  return (
    <SearchSelect
      labelKey="label"
      autoFocus
      {...rest}
      openMenuOnFocus
      params={{
        ...params,
        ...extraParams,
      }}
      entityName={entityName}
      value={null}
      onChange={(selectedOption) => {
        if (onChange) {
          onChange(selectedOption);
        }
        toggle(
          null,
          selectedOption[getIdAttribute(selectedOption.entityName)],
        );
      }}
    />
  );
};

const EntityListToggleSearchContainer: React.FC<
  EntityListToggleSearchContainerProps
> = ({ parent, entityName, childKeyName, onChange, ...rest }) => (
  <EntityToggleContainer
    action="add"
    childKeyName={childKeyName}
    entityName={entityName}
    parentName={parent.entityName}
    parentId={parent.id}
  >
    {({ toggle }) => (
      <EntityListToggleSearch
        toggle={toggle}
        onChange={onChange}
        entityName={entityName}
        parent={parent}
        {...rest}
      />
    )}
  </EntityToggleContainer>
);

export default EntityListToggleSearchContainer;
