/**
 * Labstep
 *
 * @module state/actions/thumbnail
 * @desc Redux actions for thumbnail
 */

import { APICallOptions } from 'labstep-web/models';
import { Action } from 'labstep-web/models/action.model';
import { stringifyParamsInOrder } from 'labstep-web/state/utils';

export const thumbnailFetchUrlAction = (
  fileGuid: string,
  params: Record<string, unknown>,
  options: APICallOptions,
): Action => ({
  type: 'SERVERLESS_REQUEST_THUMBNAIL',
  meta: {
    fileGuid,
    params,
    key: `${fileGuid}-${stringifyParamsInOrder(params)}`,
  },
  ...options,
});

export const thumbnailErrorAction = (
  fileGuid: string,
  params: Record<string, unknown>,
  options: APICallOptions,
): Action => ({
  type: 'THUMBNAIL_ERROR',
  meta: {
    fileGuid,
    params,
    key: `${fileGuid}-${stringifyParamsInOrder(params)}`,
  },
  ...options,
});

export const REFRESH_THUMBNAIL_STATUSES_ACTION_TYPE =
  'REFRESH_THUMBNAIL_STATUSES';
