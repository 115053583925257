import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import { EntityRelationType } from 'labstep-web/models/field-definition.model';
import styles from './styles.module.scss';
import { RelationTypeButtonContentProps } from './types';

export const RelationTypeButtonContent: React.FC<
  RelationTypeButtonContentProps
> = ({ entityRelationType }) => {
  const relationTypeInnerComponent = {
    [`${EntityRelationType.one_way}`]: (
      <>
        <Flex vAlignContent="center" spacing="gap">
          <div className={styles.text}>Source</div>
          <Icon name="long arrow alternate right" size="big" />
          <div className={styles.text}>Target</div>
        </Flex>
        <div className={styles.smallText}>e.g. contains</div>
      </>
    ),
    [`${EntityRelationType.symmetric}`]: (
      <>
        <Flex vAlignContent="center" spacing="gap">
          <div className={styles.text}>Source</div>
          <Icon name="arrows alternate horizontal" size="big" />
          <div className={styles.text}>Target</div>
        </Flex>
        <div className={styles.smallText}>e.g. related</div>
      </>
    ),
    [`${EntityRelationType.asymmetric}`]: (
      <>
        <div className={styles.smallText}>e.g. parent</div>
        <Flex vAlignContent="center" spacing="gap">
          <div className={styles.text}>Source</div>
          <Icon name="exchange" size="big" />
          <div className={styles.text}>Target</div>
        </Flex>
        <div className={styles.smallText}>e.g. child</div>
      </>
    ),
  };

  return (
    <Flex
      vAlignContent="center"
      hAlignContent="center"
      column
      className={styles.card}
    >
      {relationTypeInnerComponent[`${entityRelationType}`]}
    </Flex>
  );
};
