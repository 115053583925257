/**
 * Labstep
 *
 * @module components/ProtocolValue/List
 * @desc Protocol Value list
 */

import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import Container from 'labstep-web/components/Layout/Container';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import ProtocolValueActionCreate from 'labstep-web/components/ProtocolValue/Action/Create';
import { ProtocolValueUpdateAmountBulkAction } from 'labstep-web/components/ProtocolValue/Action/UpdateAmountBulk';
import ProtocolValueItem from 'labstep-web/components/ProtocolValue/Item';
import { ICONS } from 'labstep-web/constants/icons';
import { getChildRoute } from 'labstep-web/containers/Router/ProtocolChild';
import List from 'labstep-web/core/List';
import ListCollapsible from 'labstep-web/core/List/Collapsible';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import styles from './styles.module.scss';
import {
  IExperimentProtocolValueListContainerProps,
  IProtocolValueCardProps,
  IProtocolValueListContainerProps,
  IProtocolValueListProps,
  IProtocolValuesProps,
} from './types';

const ProtocolValuesContainer: React.FC<IProtocolValuesProps> = ({
  protocol,
  isInput = false,
}) => {
  const { entityName } = ProtocolValue;
  return (
    <ReadOnMountHOC
      type="cursor"
      entityName={entityName}
      params={{
        [protocol instanceof Experiment
          ? 'experiment_id'
          : 'protocol_id']: protocol.id,
        has_variable_template: false,
        is_input: isInput,
        is_output: !isInput,
      }}
      children={({ entities: protocolValues }) => {
        return (
          <ProtocolValueList
            protocolValues={protocolValues}
            isInput={isInput}
          />
        );
      }}
    />
  );
};

const ExperimentProtocolValueListContainer: React.FC<
  IExperimentProtocolValueListContainerProps
> = ({ experimentWorkflow, isInput }) => {
  return (
    <ReadOnMountHOC
      type="cursor"
      entityName={Experiment.entityName}
      params={{
        is_root: false,
        experiment_workflow_id: experimentWorkflow.id,
      }}
      children={({ entities }: { entities: Experiment[] }) => {
        const experiments = entities.filter((e) => !e.deleted_at);

        return (
          <ListCollapsible
            sections={experiments.map((experiment) => ({
              icon: ICONS.experiment.primary,
              text: experiment.name,
              count: isInput
                ? experiment.protocol_value_input_count
                : experiment.protocol_value_output_count,
              component: (
                <ProtocolValuesContainer
                  protocol={experiment}
                  isInput={isInput}
                />
              ),
            }))}
          />
        );
      }}
    />
  );
};

const ProtocolValueCard: React.FC<IProtocolValueCardProps> = ({
  protocolValue,
  isInput,
}) => {
  return (
    <EntityDraggable
      key={protocolValue.id}
      entityId={protocolValue.id}
      entityGuid={protocolValue.guid}
      entityName={protocolValue.entityName}
    >
      <ProtocolValueItem
        protocolValue={protocolValue}
        isInput={isInput}
      />
    </EntityDraggable>
  );
};

export const ProtocolValueList: React.FC<IProtocolValueListProps> = ({
  protocolValues,
  isInput = false,
}) => (
  <List id="protocol-value-list">
    {protocolValues.map((value) => {
      if (
        value.is_variable &&
        value.is_input &&
        !value.variable_template &&
        value.protocol_value_template_children.length > 0
      ) {
        return (
          <ReadOnMountHOC
            type="cursor"
            entityName={ProtocolValue.entityName}
            params={{
              variable_template_guid: value.guid,
              is_input: true,
              count: 100,
            }}
            children={() => {
              return (
                <ProtocolValueCard
                  protocolValue={value}
                  isInput={isInput}
                />
              );
            }}
          />
        );
      }
      return (
        <ProtocolValueCard protocolValue={value} isInput={isInput} />
      );
    })}
  </List>
);

export const ProtocolValueListContainer: React.FC<
  IProtocolValueListContainerProps
> = ({ experimentWorkflow, protocol }) => {
  const inputRoute = getChildRoute('values')!;
  const outputRoute = getChildRoute('values_outputs')!;
  const [refreshKey, setRefreshKey] = React.useState(0);

  return (
    <LayoutLinks
      links={[
        {
          children: 'Inputs',
          icon: 'sign in',
          render: () => (
            <div key={refreshKey} className={styles.container}>
              <div className={styles.scrollableContent}>
                <ProtocolValuesContainer
                  protocol={protocol}
                  isInput
                />
                {experimentWorkflow && (
                  <ExperimentProtocolValueListContainer
                    experimentWorkflow={experimentWorkflow}
                    isInput
                  />
                )}
              </div>

              <>
                <div className={styles.barrier}></div>
                <>
                  <div className={styles.actionCreate}>
                    <div className={styles.addInventoryField}>
                      <div className={styles.buttonWidth}>
                        <ProtocolValueActionCreate
                          protocol={protocol}
                        />
                      </div>
                    </div>
                    {!experimentWorkflow &&
                      protocol instanceof Experiment && (
                        <div className={styles.amountAupdate}>
                          <div className={styles.buttonWidth}>
                            <ProtocolValueUpdateAmountBulkAction
                              protocolValues={
                                protocol.protocol_values
                              }
                              setRefreshKey={() => {
                                setRefreshKey(
                                  (prevKey) => prevKey + 1,
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </>
              </>
            </div>
          ),
          route: {
            to: inputRoute.name,
            params: { id: inputRoute.params.id },
          },
        },
        {
          children: 'Outputs',
          icon: 'sign out',
          route: {
            to: outputRoute.name,
            params: { id: outputRoute.params.id },
          },
          render: () => (
            <>
              <ProtocolValuesContainer protocol={protocol} />
              {experimentWorkflow && (
                <ExperimentProtocolValueListContainer
                  experimentWorkflow={experimentWorkflow}
                  isInput={false}
                />
              )}
              <div className={styles.barrier}></div>
              <div className={styles.addInventoryField}>
                <div className={styles.buttonWidth}>
                  <ProtocolValueActionCreate
                    protocol={protocol}
                    isOutput
                  />
                </div>
              </div>
            </>
          ),
        },
      ]}
      stretch
      inverted
    />
  );
};
