/**
 * Labstep
 *
 * @module prosemirror/nodes/molecule
 * @desc Molecule node
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { getAttrsForId } from 'labstep-web/prosemirror/nodes/utils';
import { Node as ProseMirrorNode } from 'prosemirror-model';

const key = 'molecule';

const NodeMolecule: LabstepNode = {
  key,
  spec: {
    attrs: {
      guid: { default: null },
    },
    group: 'block',
    marks: '',
    parseDOM: [{ tag: 'div', getAttrs: getAttrsForId(key) }],
    toDOM(node: ProseMirrorNode) {
      const { guid } = node.attrs;
      return ['div', { [`data-${key}-guid`]: guid }];
    },
  },
};

export default NodeMolecule;
