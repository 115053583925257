/**
 * Labstep
 *
 * @module ProtocolValue/Action/Create
 * @desc ProtocolValue create.
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { getChildRoute } from 'labstep-web/containers/Router/ProtocolChild';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { navigation } from 'labstep-web/services/navigation';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import React from 'react';
import { withRouter } from 'react-router';
import { IProtocolValueActionCreateProps } from './types';

export const ProtocolValueActionCreate: React.FC<
  IProtocolValueActionCreateProps
> = ({
  protocol,
  history,
  actionComponentProps,
  options,
  isOutput,
}) => {
  const { entityName } = ProtocolValue;
  const childRoute = isOutput
    ? getChildRoute('value_show_output')
    : getChildRoute('value_show');

  return (
    <EntityActionCreate
      entityName={entityName}
      parentName={protocol.entityName}
      parentId={protocol.id}
      body={
        isOutput ? { is_output: true, is_input: false } : undefined
      }
      options={enhanceOptions({
        options,
        onSuccess: ({ response }) => {
          history.push(
            navigation.get(childRoute!.name, {
              ...childRoute!.params,
              childId: response!.result,
            }),
            { expanded: true },
          );
        },
      })}
      actionComponentProps={
        {
          dataCy: 'components/ProtocolValue/Action/Create',
          type: 'button',
          text: `Add ${getHumanReadableEntityName(
            ProtocolValue.entityName,
            false,
            true,
          )}`,
          elementProps: {
            fluid: true,
          },
          ...actionComponentProps,
        } as ActionComponentProps
      }
    />
  );
};

export default withRouter(ProtocolValueActionCreate);
