/**
 * Labstep
 *
 * @module prosemirror/state/initial-state
 * @desc ProseMirror initial state
 */

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { PremiumFeature } from 'labstep-web/models/organization.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import {
  getExtensionsPlugins,
  getSuggestionPlugins,
} from 'labstep-web/prosemirror/extensions';
import { createCommentPlugin } from 'labstep-web/prosemirror/extensions/comment';
import { reactProps } from 'labstep-web/prosemirror/extensions/external-comm';
import { createPremiumFeaturesPlugin } from 'labstep-web/prosemirror/extensions/premium-features';
import { stepsPlugin } from 'labstep-web/prosemirror/extensions/steps';
import createToolbarPlugins from 'labstep-web/prosemirror/extensions/toolbar/plugins';
import { plugins as pluginsMarks } from 'labstep-web/prosemirror/marks';
import { plugins as pluginsNodes } from 'labstep-web/prosemirror/nodes';
import schema from 'labstep-web/prosemirror/schema';
import { buildInputRules } from 'labstep-web/prosemirror/state/input-rules';
import { Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';

export const getInitialState = (
  initialState: Experiment['state'],
  initialProps: {
    entity: Experiment | Protocol;
    handleCreatePortal: (portal: any) => void;
    experimentWorkflow?: ExperimentWorkflow;
    premiumFeatures: PremiumFeature[];
  },
): EditorState =>
  EditorState.create({
    doc: initialState
      ? Node.fromJSON(schema, initialState)
      : undefined,
    schema,
    plugins: [
      ...getSuggestionPlugins(),
      ...pluginsNodes,
      ...pluginsMarks,
      ...getExtensionsPlugins(),
      createCommentPlugin(
        initialProps.entity.decorations
          ? initialProps.entity.decorations.comments
          : [],
      ),
      createPremiumFeaturesPlugin(initialProps.premiumFeatures),
      buildInputRules(schema),
      reactProps(initialProps),
      stepsPlugin,
      ...createToolbarPlugins(
        initialProps.entity,
        initialProps.experimentWorkflow,
        initialProps.handleCreatePortal,
      ),
    ],
  });
