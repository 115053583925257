/**
 * Labstep
 *
 * @module prosemirror/nodes/jupyter-notebook
 * @desc JupyterNotebook node
 */

import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { getAttrsForId } from 'labstep-web/prosemirror/nodes/utils';
import { Node as ProseMirrorNode } from 'prosemirror-model';

const key = 'jupyter_notebook';

const NodeJupyterNotebook: LabstepNode = {
  key,
  spec: {
    attrs: {
      id: { default: null },
      guid: { default: null },
    },
    group: 'block',
    marks: '',
    draggable: true,
    parseDOM: [{ tag: 'div', getAttrs: getAttrsForId(key) }],
    toDOM(node: ProseMirrorNode) {
      const { id, guid } = node.attrs;
      return [
        'div',
        { [`data-${NodeJupyterNotebook.key}-id`]: id || guid },
      ];
    },
  },
};

export default NodeJupyterNotebook;
