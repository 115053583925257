/**
 * Labstep
 *
 * @module components/ProtocolValue/Item
 * @desc Protocol Value item
 */

import ProtocolValueActionMenu from 'labstep-web/components/ProtocolValue/Action/Menu';
import ProtocolValueCheck from 'labstep-web/components/ProtocolValue/Check';
import { ICONS } from 'labstep-web/constants/icons';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import ElementCard from 'labstep-web/core/Card/Element';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import { Action } from '../Action/Menu/types';
import {
  IProtocolValueItemProps,
  IProtocolValueItemRightProps,
} from './types';

export const ProtocolValueItemRight: React.FC<
  IProtocolValueItemRightProps
> = ({ protocolValue }) =>
  protocolValue.is_experiment_child ? (
    <Flex data-cy="components/ProtocolValue/Item">
      <div style={{ width: 10 }} />
      <ProtocolValueCheck protocolValue={protocolValue} />
    </Flex>
  ) : null;

export const ProtocolValueItem: React.FC<IProtocolValueItemProps> = ({
  protocolValue,
  isInput = false,
}) => (
  <ProtocolChildRouter
    route={isInput ? 'value_show' : 'value_show_output'}
    id={protocolValue.guid}
  >
    {({ navigate }) => (
      <ElementCard
        icon={ICONS.protocol.value}
        name={protocolValue.label}
        right={
          <ProtocolValueItemRight protocolValue={protocolValue} />
        }
        onClick={navigate}
        actions={
          <ProtocolValueActionMenu
            protocolValue={protocolValue}
            actions={[Action.view, Action.delete, Action.reporting]}
          />
        }
      />
    )}
  </ProtocolChildRouter>
);

export default ProtocolValueItem;
