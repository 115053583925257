/**
 * Labstep
 *
 * @module components/ProtocolValue/Table
 * @desc Table listing all experiment values
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import ProtocolValueActionMenu from 'labstep-web/components/ProtocolValue/Action/Menu';
import { Action } from 'labstep-web/components/ProtocolValue/Action/Menu/types';
import ProtocolValueFormShowEditAmountUnit from 'labstep-web/components/ProtocolValue/Form/ShowEdit/AmountUnit';
import ProtocolValueFormShowEditName from 'labstep-web/components/ProtocolValue/Form/ShowEdit/Name';
import ProtocolValueFormShowEditResourceItem from 'labstep-web/components/ProtocolValue/Form/ShowEdit/ResourceItem';
import ResourceItemActionCreateProtocolValue from 'labstep-web/components/ResourceItem/Action/CreateProtocolValue';
import TableSimple from 'labstep-web/core/Table/Simple';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import {
  IProtocolValueTableContainerProps,
  IProtocolValueTableProps,
} from './types';

export const getColumns = () => [
  {
    header: 'Field Name',
    content: (protocolValue: ProtocolValue) => (
      <ProtocolValueFormShowEditName protocolValue={protocolValue} />
    ),
  },
  {
    header: 'Item',
    content: (protocolValue: ProtocolValue) => {
      if (protocolValue.is_input) {
        return (
          <ProtocolValueFormShowEditResourceItem
            protocolValue={protocolValue}
            children={
              <EntityLink
                entity={protocolValue.resourceItem}
                showIcon
                secondary
              />
            }
            type={null}
          />
        );
      }

      if (protocolValue.resource_item_output) {
        return (
          <EntityLink
            entity={protocolValue.resource_item_output}
            showIcon
            secondary
          />
        );
      }

      return (
        <ResourceItemActionCreateProtocolValue
          protocolValue={protocolValue}
          actionComponentProps={{
            type: 'text',
            elementProps: {
              placeholder: true,
            },
          }}
        />
      );
    },
    cellProps: { verticalAlign: 'bottom' },
  },
  {
    header: 'Amount',
    content: (protocolValue: ProtocolValue) => (
      <ProtocolValueFormShowEditAmountUnit
        protocolValue={protocolValue}
      />
    ),
    cellProps: { verticalAlign: 'bottom' },
  },
  {
    header: '',
    content: (protocolValue: ProtocolValue) => (
      <ProtocolValueActionMenu
        protocolValue={protocolValue}
        actions={[Action.view, Action.delete, Action.reporting]}
      />
    ),
    cellProps: { style: { textAlign: 'end' } },
  },
];

export const ProtocolValueTable: React.FC<
  IProtocolValueTableProps
> = ({ protocolValues }) => {
  return <TableSimple rows={protocolValues} columns={getColumns()} />;
};

export const ProtocolValueTableContainer: React.FC<
  IProtocolValueTableContainerProps
> = ({ protocol }) => {
  const params: any = {};
  if (protocol instanceof Experiment) {
    params.experiment_id = protocol.id;
  } else {
    params.protocol_id = protocol.id;
  }
  return (
    <ReadOnMountHOC
      type="cursor"
      entityName={ProtocolValue.entityName}
      params={params}
      children={({ entities: protocolValues }) => (
        <ProtocolValueTable protocolValues={protocolValues} />
      )}
    />
  );
};
