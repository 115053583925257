/**
 * Labstep
 *
 * @module components/Entity/ListCreate
 * @desc Entity List relying on Create container to create entities
 */

import EntityListCreateItem from 'labstep-web/components/Entity/ListCreate/Item';
import EntityListCreateSearch from 'labstep-web/components/Entity/ListCreate/Search';
import { ToggleContainer } from 'labstep-web/containers/Toggle';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import React from 'react';
import { useHasAccessCreate } from '../Can';
import { EntityListCreateProps } from './types';

const EntityListCreate: React.FC<EntityListCreateProps> = ({
  entityName,
  searchEntityName,
  searchParams,
  entities,
  onChangeCreateProps,
  list,
}) => {
  const createPermission = useHasAccessCreate(entityName);

  return (
    <div className="toggle">
      <ToggleContainer>
        {({ toggle, toggled }) => (
          <EntityListCreateItem
            entities={entities}
            entityName={entityName}
            toggle={toggle}
            toggled={toggled}
            createPermission={createPermission}
            list={list}
            select={() => (
              <EntityListCreateSearch
                createEntityName={entityName}
                searchEntityName={searchEntityName}
                extraParams={searchParams}
                toggle={toggle}
                onChangeCreateProps={onChangeCreateProps}
              />
            )}
          />
        )}
      </ToggleContainer>
    </div>
  );
};

export default EntityListCreate;
